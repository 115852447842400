<form class="form-servicio row" *ngIf="servicio">
    <evtc-custom-datepicker class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Fecha inicio"
        [(selectedDate)]="fechaInicioFormulario" (onSelectionChanged)="validarFormulario()"></evtc-custom-datepicker>
    <evtc-custom-timepicker class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Hora inicio"
        [(selectedTime)]="horaInicioFormulario" (onSelectionChanged)="validarFormulario()"></evtc-custom-timepicker>
    <evtc-custom-input-google-places class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Origen"
        [(selectedPlace)]="direccionGoogleOrigen" (onSelectionChanged)="validarFormulario()">
    </evtc-custom-input-google-places>
    <evtc-custom-input-google-places class="formField col-xs-12 col-sm-12 col-md-6 col-lg-6" label="Destino"
        [(selectedPlace)]="direccionGoogleDestino" (onSelectionChanged)="validarFormulario()">
    </evtc-custom-input-google-places>

    <div *ngIf="erroresEnFormulario.length > 0" class="errores-formulario-container form-group col-12">
        <ul *ngFor="let error of erroresEnFormulario">
            <li>{{error}}.</li>
        </ul>
    </div>

    <div *ngIf="avisosEnFormulario.length > 0" class="avisos-formulario-container form-group col-12">
        <ul *ngFor="let aviso of avisosEnFormulario">
            <li>{{aviso}}.</li>
        </ul>
    </div>
</form>