import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../../modules/auth/services/auth.service';
import { BehaviorSubject } from "rxjs";
import { catchError, filter, take, switchMap } from 'rxjs/operators';

@Injectable()


@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token=this.authService.getJwtToken();
    if (token){
      let authReq=req.clone({
          headers: req.headers.set('Authorization','Bearer '+token)
      });  
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
