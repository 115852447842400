import { IServicio } from "../../entity/servicio.interface";
import { DateUtils } from "../../utils/date.util";

type OpcionesEventoScheduler = {
  modificado: boolean;
};

export class EventoScheduler {

    static readonly COLOR_DEFAULT = '#626262';
    static readonly COLOR_PREASIGNADO_SIN_CONFIRMAR = '#0000003f';
    
    static readonly ESTADO_SIN_ASIGNAR = 0;
    static readonly ESTADO_ASIGNADO = 1;
    static readonly ESTADO_PREASIGNADO = 2;

    id: string;
    start_date: Date;
    end_date: Date;
    text: string;
    section_id: string;
    servicio: IServicio;
    estado: number;
    color: string;
    opciones: OpcionesEventoScheduler;

    constructor(
        servicio: IServicio,
        section_id: string,
        text: string,
        opciones: OpcionesEventoScheduler = { modificado: false },
        estado?: number
    ) {
        this.id = servicio.id.toString();
        this.servicio = servicio;
        this.section_id = section_id;
        this.text = text;
        this.opciones = opciones;

        this.sincronizarConServicio();

        if(estado) {
            this.setEstado(estado);
        }
        else if(servicio.idConductores.length > 0) {
            this.setEstado(EventoScheduler.ESTADO_ASIGNADO);
        }
        else {
            this.setEstado(EventoScheduler.ESTADO_SIN_ASIGNAR);
        }

        let colorHex = this.servicio.tipoServicio.colorHex;
        this.color = colorHex? colorHex: EventoScheduler.COLOR_DEFAULT;
    }

    public sincronizarConServicio() {
        this.start_date = this.servicio.dateFechaInicio;
        this.end_date = this.servicio.dateFechaFinConMargen;
    }

    clone() {
        return new EventoScheduler(
            this.servicio,
            this.section_id,
            this.text,
            this.opciones,
            this.estado
        );
    }

    clonePreasignadoConfirmado(newId:string, newSectionId: string) {
        let newEvento = this.clone();
        newEvento.setEstado(EventoScheduler.ESTADO_PREASIGNADO);
        newEvento.id = newId;
        newEvento.section_id = newSectionId;

        return newEvento;
    }

    clonePreasignadoSinConfirmado(newId:string, newSectionId: string) {
        let nuevoEv = this.clonePreasignadoConfirmado(newId, newSectionId);
        nuevoEv.color = EventoScheduler.COLOR_PREASIGNADO_SIN_CONFIRMAR;
        return nuevoEv;
    }

    setEstado(estado: number) {
        this.estado = estado;
    }
}
