import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../modules/auth/services/auth.service';
// Aux modules
import { SwalService } from '../../shared/services/swal.service';

@Injectable({
  providedIn: 'root'
})
export class LoginActivatedGuard {

  
  constructor(
    private authService: AuthService, 
    private router: Router,
    private message: SwalService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.authService.isLogged().subscribe(
      res => {
              if (!res) {
                this.doLogout('¡Sesión finalizada!. Debe volverse a identificarse.');
                return false;
              }
            },
      error => {
        this.doLogout('¡Error conexión API. Sesión finalizada!. Debe volverse a identificarse.');
      }
    );
    return true;
  }

  private doLogout(message:string){
    this.message.showMessageError(message);
    this.authService.logout();
  }

}

