<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-lightblue navbar-dark" 
                                [ngClass]="{'navbar-blue':color==='blue',
                                            'navbar-lightblue':color==='lightblue',
                                            'navbar-red':color==='red',
                                            'navbar-navy':color==='navy',
                                            'navbar-green':color==='green',
                                            'navbar-teal':color==='teal',
                                            'navbar-olive':color==='olive',
                                            'navbar-orange':color==='orange',
                                            'navbar-yellow':color==='yellow',
                                            'navbar-maroon':color==='maroon',
                                            'navbar-black':color==='black',
                                            'navbar-gray':color==='gray'
                            }">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
    </li>
  </ul>
  <!-- Right navbar links -->

  <ul class="navbar-nav ml-auto">
    <li class="nav-item nav-link ">
      <h6><span class="badge">{{companyName}}</span></h6>
    </li>
    
    <li class="nav-item">
      <button type="button" class="btn btn-block nav-link" (click)="logout()"><i class="fas fa-door-open"></i></button>
    </li>
    <!-- Link Configuration -->
    <!--
      <li class="nav-item">
        <a class="nav-item nav-link" routerLinkActive="active" routerLink="configuration"><i class="fas fa-cogs"></i></a>
      </li>
    -->
  </ul>
</nav>