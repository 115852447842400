import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'evtc-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {

  @Input()
  public contextMenuItems: Array<{label: string, value: string}> = [];
  @Output()
  private onContextMenuItemClick: EventEmitter<{menuItemSelected: {label: string, value: string}, $event: Event}> = new EventEmitter<{menuItemSelected: {label: string, value: string}, $event: Event}>();
  
  public seEstaMostrando: boolean = false;
  public ngStyle =  {
    position: 'fixed',
    left: '0',
    top: '0'
  };

  private onContextMenuItemClickSubsciption: Subscription;
  private seVaAMostrarConClickIzquierdo: boolean = false;

  public show(contextMenuItems: Array<{label: string, value: string}>, $event: MouseEvent) {
    this.contextMenuItems = contextMenuItems;
    this.ngStyle.left = $event.clientX + 'px';
    this.ngStyle.top = $event.clientY + 'px';
    this.seEstaMostrando = true;

    if($event.button === 0) { // CLICK IZQUIERDO
      this.seVaAMostrarConClickIzquierdo = true;
    }
  }

  public onContextMenuClick(menuItemSelected: {label: string, value: string}, $event: MouseEvent): any {
    this.seEstaMostrando = false;
    this.onContextMenuItemClick.emit({ menuItemSelected: menuItemSelected, $event });
  }

  public setOnContextMenuClickSubscription(succesFun?: any, errorFun?: any, finallyFun?: any) {
    if(this.onContextMenuItemClickSubsciption)
      this.onContextMenuItemClickSubsciption.unsubscribe();

    this.onContextMenuItemClickSubsciption = this.onContextMenuItemClick.subscribe(succesFun, errorFun, finallyFun);
  }

  @HostListener('document:click')
  documentClick(): void {
    if(this.seVaAMostrarConClickIzquierdo)
      this.seVaAMostrarConClickIzquierdo = false;
    else
      this.seEstaMostrando = false;
  }

}
