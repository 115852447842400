<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Asignación</h1>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3 col-6">
                    <!-- small card -->
                    <div class="small-box bg-info">
                        <div class="inner">
                            <h3>{{companies}}</h3>
                            <p>Asignación Manual</p>
                        </div>
                        <div class="icon">
                            <i class="fas fa-taxi"></i>
                        </div>
                        <a class="small-box-footer" routerLinkActive="active" routerLink="/asignacion/conductores">
                            Acceso <i class="fas fa-arrow-circle-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-6">
                    <!-- small card -->
                    <div class="small-box bg-olive">
                        <div class="inner">
                            <h3>{{companies}}</h3>
                            <p>Asignación automática</p>
                        </div>
                        <div class="icon">
                            <i class="fas fa-magic"></i>
                        </div>
                        <a class="small-box-footer" routerLinkActive="active" routerLink="/asignacion/automatica">
                            Acceso <i class="fas fa-arrow-circle-right"></i>
                        </a>
                    </div>
                </div>
                <!-- ./col -->
            </div>
            <!-- /.row -->
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</div>