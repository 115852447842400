// Entity
import { IAreaTrabajo } from '../entity/area-trabajo.interface';

export abstract class AreaTrabajoBll {

  public static formatToSelectValue(areaTrabajo: IAreaTrabajo): string
  {
    return areaTrabajo.nombre;
  }
}
