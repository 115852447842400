// Entity
import { IEstadoServicio } from '../entity/estado-servicio.interface';

export abstract class EstadoServicioBll {

  public static formatToSelectValue(estadoServicio: IEstadoServicio): string
  {
    return estadoServicio.nombre;
  }
}
