import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { ContextMenuComponent } from "src/app/shared/components/context-menu/context-menu.component";

import { EventoScheduler } from "src/app/core/models/helper/dhtmlxHelper/evento-scheduler.class";
import { DhtlmxSchedulerHelper } from "src/app/core/models/helper/dhtmlxHelper/dhtmlx-scheduler.helper";
import { RutaServicios } from "src/app/core/models/business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class";

// Entity
import { IAreaTrabajo } from "src/app/core/models/entity/area-trabajo.interface";
import { IConductor } from "src/app/core/models/entity/conductor.interface";
import { IVehiculo } from "src/app/core/models/entity/vehiculo.interface";
import { ITipoServicio } from "src/app/core/models/entity/tipo-servicio.interface";
import { IServicio } from "../../../core/models/entity/servicio.interface";
import { IPermisoConduccion } from "src/app/core/models/entity/permiso-conduccion.interface";
import { IConfiguracion } from "src/app/core/models/entity/configuracion.interface";
import { IEstadoServicio } from "src/app/core/models/entity/estado-servicio.interface";

// Service
import { AsignacionService } from '../services/asignacion.service';
import { SwalService } from '../../../shared/services/swal.service';
import { AuthService } from "../../auth/services/auth.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";

//Aux Modules
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EstadoVistaAsig } from "src/app/core/models/estado-vista-asig.class";
import { JsonUtils } from "src/app/core/models/utils/json.util";

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/chronos';
import { isEmpty } from "lodash";

import { ServicioBll } from "src/app/core/models/business-logic-layer/servicio.bll";
import { TipoServicioBll } from "src/app/core/models/business-logic-layer/tipo-servicio.bll";
import { EstadoServicioBll } from "src/app/core/models/business-logic-layer/estado-servicio.bll";
import { AreaTrabajoBll } from "src/app/core/models/business-logic-layer/area-trabajo.bll";
import { ConductorBll } from "src/app/core/models/business-logic-layer/conductor.bll";
import { VehiculoBll } from "src/app/core/models/business-logic-layer/vehiculo.bll";
import { DateUtils } from "src/app/core/models/utils/date.util";

defineLocale('es', esLocale);

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'evtc-asignacion-automatica',
  templateUrl: './asignacion-automatica.component.html',
  styleUrls: ['../asignacion-conductores/asignacion-conductores.component.scss'] // TODO Cambiar
})
export class AsignacionAutomaticaComponent implements OnInit, AfterViewInit {
  
  @ViewChild('containerRoot', { read: ViewContainerRef }) containerRoot: ViewContainerRef;
  @ViewChild("contextMenuScheduler") public contextMenuScheduler: ContextMenuComponent;

  readonly DateUtils = DateUtils;
  readonly schedulerHelper: DhtlmxSchedulerHelper;

  // Claves LocalStorage
  private readonly KEY_LOCALSTORAGE_ESTADOS = 'estadosVistaScheduler_automatica';

  // Movimiento Drag & Drop
  private readonly BLOCKED_MOVEMENT = 0;
  private readonly FREE_MOVEMENT = 1;
  private readonly HORIZONTAL_MOVEMENT = 2;
  private readonly VERTICAL_MOVEMENT = 3;

  private currentMovement = this.BLOCKED_MOVEMENT;
  private eventBeforeDrag: EventoScheduler;

  // Zoom
  private readonly VALOR_SUMA_X_STEP = 5;

  private readonly PREFIJO_PREASIGN = 'PREASIG_'
  private readonly PREFIJO_PREASIGN_CONFIRMADO = this.PREFIJO_PREASIGN + 'CONF_'
  private readonly PREFIJO_PREASIGN_SIN_CONFIRMAR = this.PREFIJO_PREASIGN + 'SIN_CONF_'

  // Datos BD API (Filtro)
  // Códigos Estados
  private readonly COD_RESERVADO = 'RESE';
  private readonly COD_ASIGNADO = 'ASIG';

  datosAPI = {
    estados: [
      { nombre: 'RESERVADOS', codigo: this.COD_RESERVADO },
      { nombre: 'ASIGNADOS', codigo: this.COD_ASIGNADO }
    ] as IEstadoServicio[],
    conductores: null as IConductor[],
    vehiculos: null as IVehiculo[],
    areasTrabajo: null as IAreaTrabajo[],
    tiposServicio: null as ITipoServicio[],
    permisosConduccion: null as IPermisoConduccion[],
    configuracion: null as IConfiguracion
  };

  filtroVisible: boolean = false;
  erroresEnFiltro: string[] = [];
  avisosEnFiltro: string[] = [];

  // Control de estados de la vista
  readonly NUM_MAX_RANGO_HORAS_FILTRO = 24;
  readonly NUM_MAX_ESTADOS = 20;
  estadoVistaSinAlterar: Readonly<EstadoVistaAsig>;
  estadoVistaActual: EstadoVistaAsig;
  estadosVistaScheduler: EstadoVistaAsig[] = [];

  cargandoDatos: boolean = false;
  mensajesCargasDatosEnCurso: {id:number, mensaje: string}[] = [];
  mensajeCargaDatosMostrar: string;

  generadorRutasWorker: Worker;

  public EstadoServicioBll = EstadoServicioBll;
  public AreaTrabajoBll = AreaTrabajoBll;
  public TipoServicioBll = TipoServicioBll;
  public ConductorBll = ConductorBll;
  public VehiculoBll = VehiculoBll;

  // Modal Guardar Estado Vista
  modalRefModalGuardarEstadoVista?: BsModalRef;
  tituloEstadoModalGuardar: string;

  // Modal Opciones Estados Vista
  modalRefModalEstadosVista?: BsModalRef;
  estadoVistaSelModal: EstadoVistaAsig;

  // Modal Restablecer Estados Vista
  modalRefModalRecuperarEstadoVista?: BsModalRef;

  // Elementos DOM
  // Botones opciones scheduler
  @ViewChild('desplazamiento_timeline_container') botonDesplazamiento: ElementRef<HTMLDivElement>;
  @ViewChild('boton_mas_zoom') botonMasZoom: ElementRef<HTMLDivElement>;
  @ViewChild('boton_menos_zoom') botonMenosZoom: ElementRef<HTMLDivElement>;

  // Templates
  @ViewChild('template_recuperar_estado_vista') templateRecuperarEstadoVista: TemplateRef<HTMLElement>;
  @ViewChild('template_guardar_estado_vista') templateGuardarEstadoVista: TemplateRef<HTMLElement>;
  @ViewChild('template_selector_estado_vista') templateSelectorEstadoVista: TemplateRef<HTMLElement>;
  @ViewChild('template_confirmar_eliminar_ruta') templateConfirmarEliminarRuta: TemplateRef<HTMLElement>;

  constructor(
    private asignacionService: AsignacionService,
    private swalService: SwalService,
    private modalService: BsModalService,
    private authService: AuthService,
    private localeService: BsLocaleService
  ) {
    this.schedulerHelper = new DhtlmxSchedulerHelper('scheduler_container');

    this.leerEstadosVistaLocalStorage();

    if (this.estadosVistaScheduler.length > 0) {
      this.estadoVistaActual = this.estadosVistaScheduler[0];
      this.estadoVistaSinAlterar = Object.freeze(this.estadosVistaScheduler[this.estadosVistaScheduler.length - 1]);
    }
    else
      this.initEstadoVista();
  }

  ngOnInit(): void {
    this.localeService.use('es');
    this.generadorRutasWorker = new Worker('src/app/core/workers/generador-rutas-servicios.worker.ts', { type: "module"});

    this.getDatosFromAPI(false).then((sinErrores) => {
      if(sinErrores)
        this.initVista();
    });
  }

  ngAfterViewInit(): void {
  }

  private initVista() {
    let estadosVistaRecuperados = this.estadosVistaScheduler.length > 0;
    
    this.initSheduler();

    if (estadosVistaRecuperados) {
      // TODO CAMBIAR SWAL_SERVICE
      this.modalRefModalRecuperarEstadoVista = this.openModal(this.templateRecuperarEstadoVista, 'modal-recuperar-estado', true);
    }
    else {
      this.aceptarFiltro();
    }
  }

  private initEstadoVista() {
    this.estadoVistaActual = new EstadoVistaAsig([], [], []);
    this.estadoVistaSinAlterar = Object.freeze(this.estadoVistaActual.clonar());

    // Obtenemos el rango de fechas inicial
    let ahora = new Date();
    let minutosAhora = ahora.getMinutes();
    let newMinutosAhora = (minutosAhora - minutosAhora % 5) + 5;
    ahora.setMinutes(newMinutosAhora);
    ahora.setSeconds(0, 0);

    let despues = new Date(ahora);
    despues.setHours(despues.getHours() + 12);

    let valoresFiltro = this.estadoVistaActual.getDatosFiltro();
    valoresFiltro.horaInicio = valoresFiltro.fechaInicio = valoresFiltro.fechaHoraInicio = new Date(ahora);
    valoresFiltro.horaFin = valoresFiltro.fechaFin = valoresFiltro.fechaHoraFin = new Date(despues);
  }

  private initSheduler() {
    let valoresFiltro = this.estadoVistaActual.getDatosFiltro();

    this.schedulerHelper.setOnBeforeEventChanged((eventNow: EventoScheduler, ev: Event, is_new: boolean, eventOld: EventoScheduler) => this.triggerOnBeforeEventChanged(eventNow, ev, is_new, eventOld));
    this.schedulerHelper.setOnBeforeLightbox((id: string) => false);
    this.schedulerHelper.setOnBeforeDrag((id: string, mode: string, ev: MouseEvent) => this.triggerOnBeforeDrag(id, mode, ev));
    this.schedulerHelper.setOnEventDrag((id: string, mode: string, ev: MouseEvent) => this.triggerOnEventDrag(id, mode, ev));
    this.schedulerHelper.setOnDragEnd((id: string, mode: string, ev: MouseEvent) => this.triggerOnDragEnd(id, mode, ev));
    this.schedulerHelper.setOnYScaleClick((index: number, section, ev: MouseEvent) => this.triggerOnYScaleClick(index, section, ev));
    this.schedulerHelper.setTimeLineScaleLabel((key: string, label: string, section) => this.triggerTimeLineScaleLabel(key, label, section));
    this.schedulerHelper.setTemplateEventClass((start: Date, end: Date, event: EventoScheduler) => this.setTemplateEventClass(start, end, event));
    this.schedulerHelper.setOnContextMenu((eventId: string, event: MouseEvent) => this.setOnContextMenu(eventId, event));
    this.schedulerHelper.setOnBeforeTooltip((eventId: string) => !this.contextMenuScheduler.seEstaMostrando); // TODO CAMBIAR

    this.schedulerHelper.initScheduler(
      valoresFiltro.fechaHoraInicio,
      valoresFiltro.fechaHoraFin,
      [], [], []
    );
  }

  private triggerOnBeforeEventChanged(eventNow: EventoScheduler, ev: Event, esNuevo: boolean, eventOld: EventoScheduler): boolean {
    let realizarCambios = true;
    let eventos = this.estadoVistaActual.getEventos();

    if (esNuevo) {
      let index = eventos.push(eventNow);
    }

    else {
      let index = eventos.findIndex((evento) => evento.id === eventNow.id);

      if (index === -1)
        realizarCambios = false;
      else
        eventos[index] = eventNow;
    }

    return realizarCambios;
  }

  private triggerOnBeforeDrag(id: string, mode: string, ev: MouseEvent): boolean {
    let event = this.schedulerHelper.getEvent(id);

    // Guardamos el estado inicial del evento
    this.eventBeforeDrag = event.clone();

    let seccionId = event.section_id;
    let seccionValida = this.esIdRutaValido(seccionId) || seccionId === this.schedulerHelper.KEY_SIN_ASIGNAR;
    let movimientoPermitido = (this.currentMovement != this.BLOCKED_MOVEMENT) &&
      event.estado != EventoScheduler.ESTADO_PREASIGNADO &&
      seccionValida;

    if (movimientoPermitido) {
      if (this.currentMovement === this.FREE_MOVEMENT ||
        this.currentMovement === this.VERTICAL_MOVEMENT) {
          
        // Marcamos las secciones compatibles
        this.marcarSeccionesCompatibles(event.servicio);
        this.schedulerHelper.updateView();
      }

      return true;
    }
    else
      return false; //Drag-Drop bloqueado
  }

  private triggerOnEventDrag(id: string, mode: string, ev: MouseEvent) {
    // Obtenemos el objeto del evento
    let eventAfterDrag = this.schedulerHelper.getEvent(id);

    if (this.schedulerHelper.getCurrentMode() === "timeline" && mode === 'move') {
      let destinoValido = this.esIdRutaValido(eventAfterDrag.section_id) ||
        eventAfterDrag.section_id === this.schedulerHelper.KEY_SIN_ASIGNAR;
      let bloquearMovHorizontal = this.currentMovement === this.VERTICAL_MOVEMENT;
      let bloquearMovVertical = this.currentMovement === this.HORIZONTAL_MOVEMENT || !destinoValido;

      if (bloquearMovVertical) {
        eventAfterDrag.section_id = this.eventBeforeDrag.section_id;
        this.schedulerHelper.updateEvent(id);
      }

      if (bloquearMovHorizontal) {
        eventAfterDrag.start_date = this.eventBeforeDrag.start_date;
        eventAfterDrag.end_date = this.eventBeforeDrag.end_date;
        this.schedulerHelper.updateEvent(id);
      }

      if (!this.esIdRutaValido(eventAfterDrag.section_id) &&
        eventAfterDrag.section_id != this.schedulerHelper.KEY_SIN_ASIGNAR
      ) {
        eventAfterDrag.section_id = this.eventBeforeDrag.section_id;
        this.schedulerHelper.updateEvent(id);
      }

      // En caso de haber cambiado de sección
      if (eventAfterDrag.section_id != this.eventBeforeDrag.section_id) {

        if (this.esIdRutaValido(eventAfterDrag.section_id)) {
          let listaRutas = this.estadoVistaActual.getRutas();
          let idRuta = parseInt(eventAfterDrag.section_id.substr(1));
          let rutaAsignada = listaRutas.find((r) => r.getId() === idRuta);

          if (rutaAsignada.esCompatibleConServicio(eventAfterDrag.servicio)) {
            if (this.esIdRutaValido(this.eventBeforeDrag.section_id)) {
              let idRutaOld = parseInt(this.eventBeforeDrag.section_id.substr(1));
              let rutaOld = listaRutas.find((r) => r.getId() === idRutaOld);

              rutaOld.removeServicio(eventAfterDrag.servicio.id);
            }

            rutaAsignada.addServicio(eventAfterDrag.servicio);
          }
          else {
            eventAfterDrag.section_id = this.eventBeforeDrag.section_id;
            this.schedulerHelper.updateEvent(id);
          }
        }
      }

      //Guardamos el estado actual del evento
      this.eventBeforeDrag = eventAfterDrag.clone();
    }
  }

  private triggerOnDragEnd(id: string, mode: string, ev: MouseEvent) {
    if (this.schedulerHelper.getCurrentMode() === "timeline" && mode === 'move') {
      if (this.currentMovement === this.FREE_MOVEMENT ||
        this.currentMovement === this.VERTICAL_MOVEMENT) {
        this.desmarcarConductoresCompatibles();
      }

      this.refrescarPosicionesSeccionesRutas();
      this.actualizarEventosRutas();
    }
  }

  private triggerOnYScaleClick(index: number, section, event: MouseEvent) {
    if (this.esIdRutaValido(section.key)) {
      let listaRutas = this.estadoVistaActual.getRutas();
      let idRuta = parseInt(section.key.substr(1));
      let ruta = listaRutas.find((r) => r.getId() === idRuta);

      const valorAsignarConductor = "AsignarConductor";
      const valorComprobarConductor = "ComprobarConductor";
      const valorEliminarConductor = "EliminarConductor";
      const valorAsignarVehiculo = "AsignarVehiculo";
      const valorEliminarRuta = "EliminarRuta";

      const tieneConductorAsignado = ruta.getConductor() && ruta.getConfirmacionAsignacion();
      const sePuedeEliminarRuta = !ruta.getConductor() || !ruta.getConfirmacionAsignacion();

      let contextMenuItems = [];
      contextMenuItems.push({label: (tieneConductorAsignado?'Reasignar':'Asignar') + ' conductor', value: valorAsignarConductor});
      contextMenuItems.push({label: 'Comprobar conductor', value: valorComprobarConductor});

      if(tieneConductorAsignado)
        contextMenuItems.push({label: 'Eliminar conductor', value: valorEliminarConductor});

      contextMenuItems.push({label: 'Asignar vehículo', value: valorAsignarVehiculo});

      if(sePuedeEliminarRuta)
      contextMenuItems.push({label: 'Eliminar ruta', value: valorEliminarRuta});

      let handleMenuItemClick = (data: {menuItemSelected: {label: string, value: string}, $event: Event}) => {
        switch(data.menuItemSelected.value) {
          case valorAsignarConductor: this.mostrarModalAsignacionConductorRuta(ruta); break;
          case valorComprobarConductor: this.mostrarModalAsignacionConductorRuta(ruta, true); break;
          case valorEliminarConductor: this.mostrarModalEliminarConductorRuta(ruta); break;
          case valorAsignarVehiculo: this.mostrarModalAsignacionVehiculoRuta(ruta); break;
          case valorEliminarRuta: this.mostrarModalEliminarRuta(ruta); break;
        }
      }

      this.contextMenuScheduler.setOnContextMenuClickSubscription((data) => handleMenuItemClick(data));

      this.schedulerHelper.closeTooltip();
      this.contextMenuScheduler.show(contextMenuItems, event);
    }
  }

  private triggerTimeLineScaleLabel(key: string, label: string, section): string {
    let texto = '';

    if (this.esIdConductorValido(key)) {
      let listaConductores = this.estadoVistaActual.getConductores();
      let idConductor = parseInt(key);
      let conductor = listaConductores.find((c) => c.id === idConductor);

      let codigo = conductor.codigo;
      let nombreCompleto = conductor.nombre + ' ' + conductor.apellido1;
      let nombreCompacto = conductor.nombre + ' ' + conductor.apellido1.substr(0, 1) + '.';

      if (conductor.apellido2) {
        nombreCompleto += ' ' + conductor.apellido2;
        nombreCompacto += ' ' + conductor.apellido2.substr(0, 1) + '.';
      }

      texto = '<span class="scaley-label-conductor" data-toggle="tooltip" data-placement="bottom" title="' + nombreCompleto + ' [' + conductor.permisoConduccion.nombre + '] (' + conductor.areaTrabajo.nombre + ')">' +
        '<span class="scaley-codigo-conductor">' + codigo + '</span>' + nombreCompacto +
        '</span>';
    }
    else if (this.esIdRutaValido(key)) {
      let listaRutas = this.estadoVistaActual.getRutas();
      let idRuta = parseInt(key.substr(1));
      let ruta = listaRutas.find((r) => r.getId() === idRuta);
      let conductorAsign = ruta.getConductor();

      texto = '<span class="scaley-label-ruta">R' + idRuta;

      if(ruta.getPermisoConduccionMax()) {
        texto += ' [' + ruta.getPermisoConduccionMax().nombre + ']';
      }

      if (conductorAsign) {
        let isConfirmado = ruta.getConfirmacionAsignacion();
        let nombreCompleto = conductorAsign.nombre + ' ' + conductorAsign.apellido1;

        if (conductorAsign.apellido2)
          nombreCompleto += ' ' + conductorAsign.apellido2;

        let textoTooltip = conductorAsign.codigo + ' - ' + nombreCompleto + ' (' + (isConfirmado ? 'ASIGNADO' : 'PRE-ASIGNADO') + ')';

        let claseSpan = isConfirmado ? 'cod-conductor-reservado' : 'cod-conductor-prereservado';

        texto += '<span class="scaley-codigo-conductor ' + claseSpan + '" data-toggle="tooltip" data-placement="bottom" title="' + textoTooltip + '">' + conductorAsign.codigo + '</span>';

      }

      texto += '</span>';
    }

    return texto;
  }
  
  private setTemplateEventClass(start: Date, end: Date, event: EventoScheduler) {
    let className = 'event-bar-container';

    if (event.opciones.modificado)
      className += ' event-modificado';
    if (this.schedulerHelper.getSegundosInicialesFueraDeRango(event) > 0)
      className += ' event-container-fuera-rango-inicial';
    if (this.schedulerHelper.getSegundosFinalesFueraDeRango(event) > 0)
      className += ' event-container-fuera-rango-final';

    if (event.id.startsWith(this.PREFIJO_PREASIGN_SIN_CONFIRMAR))
      className += ' event-container-preasignado-sin-confirmar';
    else if (event.id.startsWith(this.PREFIJO_PREASIGN_CONFIRMADO))
      className += ' event-container-preasignado-confirmado';

    return className;
  }
  
  private setOnContextMenu(eventId: string, event: MouseEvent) {
    let mostrarContextMenuNavegador = true;

    if(eventId){
      mostrarContextMenuNavegador = false;

      let eventScheduler = this.schedulerHelper.getEvent(eventId);

      const valorAsignarVehiculo = "AsignarVehiculo";
      const valorEliminarVehiculo = "EliminarrVehiculo";
      const valorVerDetalles = "VerDetalles";
      const valorEditarServicio = "EditarSevicio";

      const tieneVehiculoAsignado = !isEmpty(eventScheduler.servicio.vehiculo);

      let contextMenuItems = [];
      contextMenuItems.push({label: (tieneVehiculoAsignado?'Reasignar':'Asignar') + ' vehículo', value: valorAsignarVehiculo});

      if(tieneVehiculoAsignado)
        contextMenuItems.push({label: 'Eliminar vehículo', value: valorEliminarVehiculo});
      
      contextMenuItems.push({label: 'Editar servicio', value: valorEditarServicio});

      let handleMenuItemClick = (data: {menuItemSelected: {label: string, value: string}, $event: Event}) => {
        switch(data.menuItemSelected.value) {
          case valorAsignarVehiculo: this.mostrarModalAsignacionVehiculoServicio(eventScheduler); break;
          case valorEliminarVehiculo: this.mostrarModalEliminarVehiculoServicio(eventScheduler); break;
          case valorEditarServicio: this.mostrarModalEditarServicio(eventScheduler); break;
        }
      }

      this.contextMenuScheduler.setOnContextMenuClickSubscription((data) => handleMenuItemClick(data));

      this.schedulerHelper.closeTooltip();
      this.contextMenuScheduler.show(contextMenuItems, event);
    }
    
    return mostrarContextMenuNavegador;
  }

  private marcarSeccionesCompatibles(servicio?: IServicio) {
    let that = this;

    this.schedulerHelper.setClassScaleY((key: string, label: string, section) => {
      if (servicio && that.esIdRutaValido(key)) {
        let listaRutas = that.estadoVistaActual.getRutas();
        let idRuta = parseInt(key.substr(1));
        let ruta = listaRutas.find((r) => r.getId() === idRuta);

        return (ruta.esCompatibleConServicio(servicio)) ?
            'seccion_compatible_drag' :
            'seccion_incompatible_drag';
      }

      return '';
    });
  }

  private desmarcarConductoresCompatibles() {
    this.schedulerHelper.setClassScaleY((key: string, label: string, section) => '');
  }

  private esIdConductorValido(id: string): boolean {
    return (/^\-?\d+$/).test(id);
  }

  private esIdRutaValido(id: string): boolean {
    return (/^R\d+$/).test(id);
  }

  public aumentarZoom() {
    let limiteAlcanzado = this.schedulerHelper.sumarMinutosXStep(- this.VALOR_SUMA_X_STEP);

    this.botonMenosZoom.nativeElement.classList.remove('deshabilitado');

    if (limiteAlcanzado)
      this.botonMasZoom.nativeElement.classList.add('deshabilitado');
    else
      this.botonMenosZoom.nativeElement.classList.remove('deshabilitado');
  }

  public reducirZoom() {
    let limiteAlcanzado = this.schedulerHelper.sumarMinutosXStep(this.VALOR_SUMA_X_STEP);

    this.botonMasZoom.nativeElement.classList.remove('deshabilitado');

    if (limiteAlcanzado)
      this.botonMenosZoom.nativeElement.classList.add('deshabilitado');
    else
      this.botonMenosZoom.nativeElement.classList.remove('deshabilitado');
  }

  public setVisibilidadFiltro(visible: boolean) {
    this.filtroVisible = visible;
  }

  public validarFiltro() {
    this.erroresEnFiltro = [];
    this.avisosEnFiltro = [];

    this.validarFechasFiltro();
    this.validarConductoresFiltro();
  }

  private validarFechasFiltro() {
    let valoresFiltro = this.estadoVistaActual.getDatosFiltro();

    if (!valoresFiltro.fechaInicio) {
      this.erroresEnFiltro.push('Debes introducir una fecha inicio');
    }
    else if (valoresFiltro.fechaInicio.toString() === 'Invalid Date') {
      this.erroresEnFiltro.push('La fecha inicio es inválida');
    }

    if (!valoresFiltro.horaInicio) {
      this.erroresEnFiltro.push('Debes introducir una hora inicio');
    }
    else if (valoresFiltro.horaInicio.toString() === 'Invalid Date') {
      this.erroresEnFiltro.push('La hora inicio es inválida');
    }

    if (!valoresFiltro.fechaFin) {
      this.erroresEnFiltro.push('Debes introducir una fecha fin');
    }
    else if (valoresFiltro.fechaFin.toString() === 'Invalid Date') {
      this.erroresEnFiltro.push('La fecha fin es inválida');
    }

    if (!valoresFiltro.horaFin) {
      this.erroresEnFiltro.push('Debes introducir una hora fin');
    }
    else if (valoresFiltro.horaFin.toString() === 'Invalid Date') {
      this.erroresEnFiltro.push('La hora fin es inválida');
    }

    if (this.erroresEnFiltro.length === 0) {
      let fechaHoraInicioNew = new Date(
        valoresFiltro.fechaInicio.getFullYear(),
        valoresFiltro.fechaInicio.getMonth(),
        valoresFiltro.fechaInicio.getDate(),
        valoresFiltro.horaInicio.getHours(),
        valoresFiltro.horaInicio.getMinutes()
      );
      let fechaHoraFinNew = new Date(
        valoresFiltro.fechaFin.getFullYear(),
        valoresFiltro.fechaFin.getMonth(),
        valoresFiltro.fechaFin.getDate(),
        valoresFiltro.horaFin.getHours(),
        valoresFiltro.horaFin.getMinutes()
      );

      valoresFiltro.fechaHoraInicio = fechaHoraInicioNew;
      valoresFiltro.fechaHoraFin = fechaHoraFinNew;
      let ahora = new Date();
      let milisecondsMax = this.NUM_MAX_RANGO_HORAS_FILTRO * 60 * 60 * 1000;

      if((fechaHoraInicioNew > fechaHoraFinNew)) {
        this.erroresEnFiltro.push('La fecha y hora Fin debe ser superior a la fecha y hora Origen');
      }

      else if((fechaHoraFinNew.getTime() - fechaHoraInicioNew.getTime()) > milisecondsMax) {
        this.erroresEnFiltro.push('No se puede visualizar un rango de horas superior a ' + this.NUM_MAX_RANGO_HORAS_FILTRO);
      }

      else if((fechaHoraInicioNew < ahora)) {
        this.avisosEnFiltro.push('La fecha y hora Origen es anterior');
      }
    }
  }

  private validarConductoresFiltro() {
    let valoresFiltro = this.estadoVistaActual.getDatosFiltro();
    let hayColision = false;

    if (valoresFiltro.areasTrabajo && valoresFiltro.areasTrabajo.length > 0 &&
      valoresFiltro.conductores && valoresFiltro.conductores.length > 0
    ) {
      let listaConductores = this.datosAPI.conductores;

      valoresFiltro.conductores.forEach((conductorFiltro) => {
        let conductor = listaConductores.find(c => c.id === conductorFiltro.id);
        let coincidencia = valoresFiltro.areasTrabajo.some((areaTrabajoFiltro) => conductor.areaTrabajo.id === areaTrabajoFiltro.id);

        if (!coincidencia)
          hayColision = true;
      });;
    }

    if (hayColision) {
      this.erroresEnFiltro.push('Hay conductores filtrados que no pertenecen a ninguna área de trabajo filtrada');
    }
  }

  public cambiarDesplazamientoDrag() {
    const classBloqueado = 'fa-ban';
    const classLibre = 'fa-arrows-alt';
    const classHorizontal = 'fa-arrows-alt-h';
    const classVertical = 'fa-arrows-alt-v';
    let logoDesplazamiento = this.botonDesplazamiento.nativeElement.getElementsByTagName('i')[0];
    let currentClasslist = logoDesplazamiento.classList;
    let currentClass = currentClasslist[1];

    currentClasslist.remove(currentClass);

    switch (currentClass) {
      case classBloqueado:
        currentClasslist.add(classVertical);
        this.currentMovement = this.VERTICAL_MOVEMENT;
        break;
      case classVertical:
        currentClasslist.add(classBloqueado);
        this.currentMovement = this.BLOCKED_MOVEMENT;
        break;
    }
  }

  public preguntarAplicarFiltro() {
    this.swalService.showMessageQuestion(
      "REALIZAR BÚSQUEDA",
      "<p>¿Desea aplicar el filtro actual?</p><p>Una vez actualizados los resultados, se eliminarán los estados guardados de forma local.</p>"
    ).then((respuesta) => {
      if (respuesta.value) {
        this.aceptarFiltro();
      }
    });
  }

  private aceptarFiltro() {
    if (this.erroresEnFiltro.length === 0) {
      this.setVisibilidadFiltro(false);

      this.actualizarServiciosFiltrados();
    }
  }

  private openModal(template: TemplateRef<any>, claseCss: string, ignoreBackdropClick: boolean = false): BsModalRef<any> {
    return this.modalService.show(
      template,
      Object.assign({}, { class: claseCss, ignoreBackdropClick: ignoreBackdropClick })
    );
  }

  private actualizarServiciosFiltrados() {
    let valoresFiltro = this.estadoVistaActual.getDatosFiltro();

    let estadosFiltro = valoresFiltro.estado ?
      [valoresFiltro.estado] :
      this.datosAPI.estados;

    let idCargaDatos = this.iniciarCargaDatos('Obteniendo servicios de API');
    this.getServiciosFromApi(
      valoresFiltro.fechaHoraInicio,
      valoresFiltro.fechaHoraFin,
      estadosFiltro,
      valoresFiltro.areasTrabajo,
      valoresFiltro.tiposServicio,
      valoresFiltro.conductores,
      valoresFiltro.vehiculos
    ).then(servicios => {
      servicios.forEach((servicio) => ServicioBll.calcularMargenes(servicio, this.datosAPI.configuracion));
      this.recargarScheduler(servicios);
    })
    .catch(() => this.authService.logout())
    .finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getConductoresFromApi(): Promise<IConductor[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo conductores de API');

    return new Promise<IConductor[]>((resolve, reject) => {
      this.asignacionService.getConductoresActivos()
        .subscribe(
          data => {
            if (!data.error) {
              let conductores = data.result['conductores'] as IConductor[];
              resolve(conductores);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getVehiculosFromApi(): Promise<IVehiculo[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo vehículos de API');

    return new Promise<IVehiculo[]>((resolve, reject) => {
      this.asignacionService.getVehiculosActivos()
        .subscribe(
          data => {
            if (!data.error) {
              let vehiculos = data.result['vehiculos'] as IVehiculo[];
              resolve(vehiculos);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getAreasTrabajoFromApi(): Promise<IAreaTrabajo[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo áreas de trabajo de API');

    return new Promise<IAreaTrabajo[]>((resolve, reject) => {
      this.asignacionService.getAreasTrabajo()
        .subscribe(
          data => {
            if (!data.error) {
              let areasTrabajo = data.result['areasTrabajo'] as IAreaTrabajo[];
              resolve(areasTrabajo);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getTiposServicioFromApi(): Promise<ITipoServicio[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo tipos de servicio de API');

    return new Promise<ITipoServicio[]>((resolve, reject) => {
      this.asignacionService.getTiposServicio()
        .subscribe(
          data => {
            if (!data.error) {
              let tiposServicio = data.result['tiposServcicio'] as ITipoServicio[];
              resolve(tiposServicio);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getPermisosConduccionFromApi(): Promise<IPermisoConduccion[]> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo permisos deconducción de API');

    return new Promise<IPermisoConduccion[]>((resolve, reject) => {
      this.asignacionService.getPermisosConduccion()
        .subscribe(
          data => {
            if (!data.error) {
              let permisosConduccion = data.result['permisosConduccion'] as IPermisoConduccion[];
              resolve(permisosConduccion);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getConfiguracionFromApi(): Promise<IConfiguracion> {
    let idCargaDatos = this.iniciarCargaDatos('Obteniendo configuración de API');

    return new Promise<IConfiguracion>((resolve, reject) => {
      this.asignacionService.getConfiguracion()
        .subscribe(
          data => {
            if (!data.error) {
              let configuracion = data.result['configuracion'] as IConfiguracion;
              resolve(configuracion);
            }
            else
              reject()
          },
          error => reject(),
        );
    }).finally(() => this.finalizarCargaDatos(idCargaDatos));
  }

  private getServiciosFromApi(
    fechaMin: Date,
    fechaMax: Date,
    estados: IEstadoServicio[],
    areasTrabajo?: IAreaTrabajo[],
    tiposServicio?: ITipoServicio[],
    conductores?: IConductor[],
    vehiculos?: IVehiculo[]
  ): Promise<IServicio[]> {
    return new Promise<IServicio[]>((resolve, reject) => {
      this.asignacionService.getServices(
      DateUtils.parseDateToString(fechaMin),
      DateUtils.parseDateToString(fechaMax),
      estados,
      areasTrabajo,
      tiposServicio,
      conductores,
      vehiculos
    ).subscribe(
      data => {
        if (!data.error) {
          let servicios = data.result['servicios'] as IServicio[];
          resolve(servicios);
        }
        else
          reject()
      },
      error => reject(),
    );
    });
  }

  public async getDatosFromAPI(actualizarServicios = false) {
    let sinErrores = true;
    
    await Promise.all([
      this.getConductoresFromApi().then(res => this.datosAPI.conductores = res),
      this.getVehiculosFromApi().then(res => this.datosAPI.vehiculos = res),
      this.getAreasTrabajoFromApi().then(res => this.datosAPI.areasTrabajo = res),
      this.getTiposServicioFromApi().then(res => this.datosAPI.tiposServicio = res),
      this.getPermisosConduccionFromApi().then(res => this.datosAPI.permisosConduccion = res),
      this.getConfiguracionFromApi().then(res => this.datosAPI.configuracion = res)
    ]).catch(() => {
      sinErrores = false;
      this.authService.logout();
    });

    if (actualizarServicios) {
      this.actualizarServiciosFiltrados();
    }
    
    return sinErrores
  }

  private recargarScheduler(servicios: IServicio[]) {
    let valoresFiltro = this.estadoVistaActual.getDatosFiltro();

    let estadoFiltro = valoresFiltro.estado ?
      valoresFiltro.estado : null;

    let conductores = this.datosAPI.conductores;

    // Filtrado de conductores según campos rellenos de filtro
    if (valoresFiltro.conductores && valoresFiltro.conductores.length > 0) {
      conductores = conductores.filter((conductor) =>
      valoresFiltro.conductores.some((conductorFiltro) => conductorFiltro.id === conductor.id)
      );
    }

    if (valoresFiltro.areasTrabajo && valoresFiltro.areasTrabajo.length > 0) {
      conductores = conductores.filter((conductor) =>
      valoresFiltro.areasTrabajo.some((areaTrabajoFiltro) => areaTrabajoFiltro.id === conductor.areaTrabajo.id)
      );
    }

    if (estadoFiltro && estadoFiltro.codigo === this.COD_ASIGNADO) {
      this.actualizarDatosVistaYScheduler(
        valoresFiltro.fechaHoraInicio,
        valoresFiltro.fechaHoraFin,
        conductores,
        servicios,
        [],
        true
      );
    }
    else {
      let idCargaDatos = this.iniciarCargaDatos('Creando rutas óptimas');
      let that = this;
      let serviciosConConductor = [];
      let rutasGeneradas = [];

      this.generadorRutasWorker.onmessage = function (oEvent) {
        let rutasGeneradasSinFormato = JsonUtils.fromJson(oEvent.data) as any[]
        rutasGeneradas = rutasGeneradasSinFormato.map((object) => RutaServicios.fromJsonObject(object));
        
        that.actualizarDatosVistaYScheduler(valoresFiltro.fechaHoraInicio, valoresFiltro.fechaHoraFin, conductores, serviciosConConductor, rutasGeneradas, true);
        that.finalizarCargaDatos(idCargaDatos);
      };

      if (estadoFiltro && estadoFiltro.codigo === this.COD_RESERVADO)
        this.generadorRutasWorker.postMessage(JsonUtils.toJson(servicios));
      else {
        let serviciosSinAsignar = servicios.filter(s => s.estado.codigo === this.COD_RESERVADO);
        serviciosConConductor = servicios.filter(s => s.estado.codigo === this.COD_ASIGNADO);
        
        this.generadorRutasWorker.postMessage(JsonUtils.toJson(serviciosSinAsignar));
      }
    }
  }

  public actualizarDatosVistaYScheduler(fechaHoraInicio: Date, fechaHoraFin: Date, conductores: IConductor[], servicios: IServicio[], rutas: RutaServicios[], limpiarEstadosVista: boolean) {
    this.estadoVistaActual.setConductores(conductores);
    this.estadoVistaActual.setRutas(rutas);

    this.schedulerHelper.initScheduler(fechaHoraInicio, fechaHoraFin, conductores, servicios, rutas);
    this.actualizarConductoresVisibles();

    this.estadoVistaActual.setEventos(this.schedulerHelper.getEvents());

    if(limpiarEstadosVista) {
      this.estadosVistaScheduler = [this.estadoVistaActual.capturarEstado('INICIAL')];
      this.estadoVistaSinAlterar = Object.freeze(this.estadoVistaActual.clonar());

      this.guardarEstadosVistaLocalStorage();
    }
  }

  private getMaxIdRuta() {
    let ids = this.estadoVistaActual.getRutas().map((r) => r.getId());
    return Math.max(...ids);
  }

  private actualizarEventosRutas() {
    let eventos = this.schedulerHelper.getEvents();
    let eventosAdd = [];

    // Eliminamos posibles proyecciones de asignación Ruta-Conductor
    eventos = eventos.filter((e: EventoScheduler) => !e.id.startsWith(this.PREFIJO_PREASIGN));

    this.estadoVistaActual.getRutas().forEach((ruta) => {
      // Creamos proyecciones de asignación en caso de tener un conductor asignado
      if (ruta.getConductor()) {
        let seccionRutaId = 'R' + ruta.getId();
        let eventosRuta = eventos.filter((e: EventoScheduler) => e.section_id === seccionRutaId);
        let seccionConductorId = ruta.getConductor().id.toString();

        let eventosConductor;
        if (ruta.getConfirmacionAsignacion())
          eventosConductor = eventosRuta.map((ev) => ev.clonePreasignadoConfirmado(this.PREFIJO_PREASIGN_CONFIRMADO + ev.id, seccionConductorId));
        else
          eventosConductor = eventosRuta.map((ev) => ev.clonePreasignadoSinConfirmado(this.PREFIJO_PREASIGN_SIN_CONFIRMAR + ev.id, seccionConductorId));

        eventosAdd = eventosAdd.concat(eventosConductor);
      }
    });

    eventos = eventos.concat(eventosAdd);
    this.schedulerHelper.setEvents(eventos);
    this.actualizarConductoresVisibles(eventos);
  }

  public eventBotonCrearRuta() {
    this.crearNuevaRuta();
  }

  private actualizarConductoresVisibles(eventos?: EventoScheduler[]) {
    eventos = eventos ? eventos : this.schedulerHelper.getEvents();
    let conductoresVisibles = [] as IConductor[];

    eventos = eventos.filter((e) => this.esIdConductorValido(e.section_id));
    eventos.forEach((evento) => {
      let conductorId = parseInt(evento.section_id);

      if (!conductoresVisibles.some((c) => c.id === conductorId)) {
        let conductor = this.estadoVistaActual.getConductores().find((c) => c.id === conductorId);
        conductoresVisibles.push(conductor);
      }
    });

    this.schedulerHelper.setSectionsConductores(conductoresVisibles);
  }

  private crearNuevaRuta() {
    let listaRutas = this.estadoVistaActual.getRutas();
    let nextId = this.getMaxIdRuta() + 1;
    let nuevaRuta = new RutaServicios(nextId, []);
    listaRutas.push(nuevaRuta);
    this.refrescarPosicionesSeccionesRutas();
  }

  private refrescarPosicionesSeccionesRutas() {
    this.schedulerHelper.setSectionesRutas(this.estadoVistaActual.getRutas());
  }


  private eliminarRuta(ruta: RutaServicios) {
    let listaRutas = this.estadoVistaActual.getRutas();
    let indexRuta = listaRutas.findIndex((r) => r.getId() === ruta.getId());
    let rutaEliminar = indexRuta > -1? listaRutas[indexRuta]: null;

    if (rutaEliminar) {
      let serviciosRutaEliminar = rutaEliminar.getServicios();

      if (serviciosRutaEliminar.length > 0) {
        let idsEliminar = serviciosRutaEliminar.map((s) => s.id.toString());
        // Desasignamos los servicios de la ruta
        this.schedulerHelper.changeSection(idsEliminar, this.schedulerHelper.KEY_SIN_ASIGNAR);
        this.estadoVistaActual.setEventos(this.schedulerHelper.getEvents());

        // TODO REFRESCAR BOOLEAN MODIFICADO EN EVENTOS
      }

      listaRutas.splice(indexRuta, 1);

      this.refrescarPosicionesSeccionesRutas();
      this.actualizarEventosRutas();
    }
  }

  public guardarEstadoVistaActual() {
    let estado = this.tituloEstadoModalGuardar ?
      this.estadoVistaActual.capturarEstado(this.tituloEstadoModalGuardar) :
      this.estadoVistaActual.capturarEstado();

    this.estadosVistaScheduler.unshift(estado);
    this.guardarEstadosVistaLocalStorage();

    this.modalRefModalGuardarEstadoVista.hide();
  }

  public mostrarModalGuardarEstadoVista() {
    this.tituloEstadoModalGuardar = null;
    this.modalRefModalGuardarEstadoVista = this.openModal(this.templateGuardarEstadoVista, 'modal-guardar-estado-vista');
  }

  public mostrarModalEstadosVista() {
    this.estadoVistaSelModal = this.estadosVistaScheduler.length > 0 ? this.estadosVistaScheduler[0] : null;
    this.modalRefModalEstadosVista = this.openModal(this.templateSelectorEstadoVista, 'modal-estados-vistas');
  }

  private restablecerVista(estadoVista: EstadoVistaAsig) {
    this.estadoVistaActual = estadoVista.capturarEstado();
    this.schedulerHelper.restablecerScheduler(this.estadoVistaActual);
    this.actualizarEventosRutas();
  }

  public eventBotonRestablecerVista(estadoVista: EstadoVistaAsig) {
    this.restablecerVista(estadoVista);

    this.modalRefModalEstadosVista.hide();
  }

  public eventAceptarRecuperarEstadosVista() {
    this.restablecerVista(this.estadoVistaActual);

    this.modalRefModalRecuperarEstadoVista.hide();
  }

  public eventCancelarRecuperarEstadosVista() {
    this.initEstadoVista();
    this.actualizarServiciosFiltrados();

    this.modalRefModalRecuperarEstadoVista.hide();
  }

  private guardarEstadosVistaLocalStorage() {
    if (this.estadosVistaScheduler.length > 0)
      localStorage.setItem(this.KEY_LOCALSTORAGE_ESTADOS, JsonUtils.toJson(this.estadosVistaScheduler));
    else
      localStorage.removeItem(this.KEY_LOCALSTORAGE_ESTADOS);
  }

  private leerEstadosVistaLocalStorage() {
    let estadosVistaScheduler = localStorage.getItem(this.KEY_LOCALSTORAGE_ESTADOS);

    if (estadosVistaScheduler) {
      let arrayEstados = JsonUtils.fromJson(estadosVistaScheduler);
      arrayEstados = arrayEstados.map((estado) => EstadoVistaAsig.fromJsonObject(estado));

      this.estadosVistaScheduler = arrayEstados;

      // TODO Comprobar validez de los datos ante cambios externos en BD
    }
  }

  public preguntarSubirCambiosBD() {
    this.swalService.showMessageQuestion(
      "SUBIR CAMBIOS",
      "<p>¿Desea subir los cambios actuales?</p><p>Una vez se hayan guardado los cambios, se eliminarán los estados guardados de forma local.</p>"
    ).then((respuesta) => {
      if (respuesta.value) {
        this.subirCambiosBD();
      }
    });
  }

  private subirCambiosBD() {
    let servicios =  this.estadoVistaActual.getServiciosAlterados();

    let idCargaDatos = this.iniciarCargaDatos('Guardando en la nube');
    this.asignacionService.putServicios(servicios).subscribe(
      data => {
        if (!data.error) {
          this.swalService.showMessageInfo('Cambios actualizados con éxito');
          this.aceptarFiltro();
        }
        else {
          this.swalService.showMessageError('Error al guardar los cambios');
        }
      },
      error => {
        this.swalService.showMessageError('Error al guardar los cambios');
      },
      () => this.finalizarCargaDatos(idCargaDatos)
    );
  }

  public iniciarCargaDatos(mensaje: string): number {
    let nextId = Math.max(...this.mensajesCargasDatosEnCurso.map((mensaje) => mensaje.id)) + 1;
    this.mensajesCargasDatosEnCurso.push({id: nextId, mensaje: mensaje});

    return nextId;
  }

  public finalizarCargaDatos(idCargaDatos: number) {
    let index = this.mensajesCargasDatosEnCurso.findIndex((mensaje) => mensaje.id === idCargaDatos);

    if(index > -1) {
      this.mensajesCargasDatosEnCurso.splice(index, 1);
    }
  }

  private mostrarModalAsignacionVehiculo(listaVehiculos: IVehiculo[], indicesSeleccionados: number[]) {
    return this.swalService.showModalWithSelect(
      null,
      'ASIGNACIÓN DE VEHÍCULO',
      'Vehiculo asignado',
      this.containerRoot,
      listaVehiculos,
      false,
      'SIN ASIGNAR',
      indicesSeleccionados,
      VehiculoBll.formatToSelectValue
    );
  }

  private mostrarModalAsignacionConductor(listaConductores: IConductor[], indicesSeleccionados: number[], esComprobacion = false) {
    return this.swalService.showModalWithSelect(
      null,
      (esComprobacion?'COMPROBACIÓN':'ASIGNACIÓN') +' DE CONDUCTOR',
      'Conductor asignado',
      this.containerRoot,
      listaConductores,
      false,
      'SIN ASIGNAR',
      indicesSeleccionados,
      ConductorBll.formatToSelectValue
    );
  }

  private mostrarModalAsignacionVehiculoServicio(eventScheduler: EventoScheduler) {
    let servicio = eventScheduler.servicio;
    let vehiculosCompatibles = this.datosAPI.vehiculos.filter((vehiculo) => ServicioBll.esCompatibleConVehiculo(servicio, vehiculo));
    let indexSelected = servicio.vehiculo?
      vehiculosCompatibles.findIndex((vehiculo) => vehiculo.id === servicio.vehiculo.id):
      -1;
    let initialSelectedIndexes = indexSelected >= 0? [indexSelected]: [];

    this.mostrarModalAsignacionVehiculo(vehiculosCompatibles, initialSelectedIndexes).then((resultado) => {
      if(resultado.isConfirmed) {
        servicio.vehiculo = resultado.value;
        this.comprobarCambiosEnEventos();
        this.schedulerHelper.updateView();
      }
    });
  }

  private mostrarModalAsignacionVehiculoRuta(ruta: RutaServicios) {
    let vehiculosCompatibles = this.datosAPI.vehiculos.filter((vehiculo) =>
      ruta.getServicios().some((servicio) => ServicioBll.esCompatibleConVehiculo(servicio, vehiculo))
    );

    this.mostrarModalAsignacionVehiculo(vehiculosCompatibles, []).then((resultado) => {
      if(resultado.isConfirmed) {
        let vehiculo = resultado.value as IVehiculo;

        let servicios = ruta.getServicios();
        let serviciosCompatibles = servicios.filter((servicio) => vehiculo && ServicioBll.esCompatibleConVehiculo(servicio, vehiculo));

        if(servicios.length > serviciosCompatibles.length) {
          this.swalService.showMessageQuestion(
            'Algunos servicios no son compatibles',
            '<p>¿Desea aceptar la asignación para aquellos servicios compatibles?</p>',
            'Asignar de todos modos'
          ).then((resultado) => {
            if(resultado.isConfirmed) {
              serviciosCompatibles.forEach((servicio) => servicio.vehiculo = vehiculo);
              this.comprobarCambiosEnEventos();
            }
          });
        }
        else {
          serviciosCompatibles.forEach((servicio) => servicio.vehiculo = vehiculo);
          this.comprobarCambiosEnEventos();
        }
        
        this.schedulerHelper.updateView();
      }
    });
  }

  private mostrarModalEliminarVehiculoServicio(eventScheduler: EventoScheduler) {
    let servicio = eventScheduler.servicio;
    let titulo = "ELIMINAR VEHÍCULO";
    let texto = "<p>¿Desea eliminar el vehículo con matricula " + servicio.vehiculo.matricula 
      + " del servicio con id " + servicio.id + "?</p>";

    this.swalService.showMessageQuestion(titulo, texto).then((resultado) => {
      if(resultado.isConfirmed) {
        servicio.vehiculo = null;
        this.comprobarCambiosEnEventos();
        this.schedulerHelper.updateView();
      }
    });
  }

  private mostrarModalEditarServicio(eventScheduler: EventoScheduler) {
    let servicioMod = Object.assign({}, eventScheduler.servicio);

    this.swalService.showModalModifyService(servicioMod, this.containerRoot).then((resultado) => {
      if(resultado.isConfirmed) {
        Object.assign(eventScheduler.servicio, servicioMod);
        ServicioBll.sincronizarFechasConInicioYMargenes(eventScheduler.servicio);

        eventScheduler.sincronizarConServicio();
        this.comprobarCambiosEnEventos();
        this.schedulerHelper.updateEvent(eventScheduler.id);
      }
    });
  }

  private mostrarModalEliminarRuta(ruta: RutaServicios) {
    let titulo = "ELIMINAR RUTA";
    let texto = "<p>¿Desea eliminar la ruta con id " + ruta.getId() + "?</p>";
    texto += '<p>Todos sus servicios se dsplazarán a la sección "SIN ASIGNAR"</p>';

    this.swalService.showMessageQuestion(titulo, texto).then((resultado) => {
      if(resultado.isConfirmed) {
        this.eliminarRuta(ruta);
        this.comprobarCambiosEnEventos();
      }
    });
  }

  private mostrarModalEliminarConductorRuta(ruta: RutaServicios) {
    let titulo = "ELIMINAR CONDUCTOR";
    let texto = "<p>¿Desea eliminar el conductor con codigo " + ruta.getConductor().codigo + " de la ruta con id " + ruta.getId() + "?</p>";

    this.swalService.showMessageQuestion(titulo, texto).then((resultado) => {
      if(resultado.isConfirmed) {
        ruta.setConductor(null);
        ruta.desconfirmarAsignacion();
        
        this.comprobarCambiosEnEventos();
        this.actualizarEventosRutas();
        this.refrescarPosicionesSeccionesRutas();
      }
    });
  }

  private mostrarModalAsignacionConductorRuta(ruta: RutaServicios, esComprobacion = false) {
    let conductoresCompatibles = this.datosAPI.conductores.filter((conductor) => ruta.esCompatibleConConductor(conductor));
    let indexSelected = ruta.getConductor()?
      conductoresCompatibles.findIndex((conductor) => conductor.id === ruta.getConductor().id):
      -1;
    let initialSelectedIndexes = indexSelected >= 0? [indexSelected]: [];

    this.mostrarModalAsignacionConductor(conductoresCompatibles, initialSelectedIndexes, esComprobacion).then((resultado) => {
      if(resultado.isConfirmed) {
        let newConductor = resultado.value as IConductor;
        this.schedulerHelper.updateView();

        ruta.setConductor(newConductor);
        esComprobacion?
          ruta.desconfirmarAsignacion():
          ruta.confirmarAsignacion();
        
        this.comprobarCambiosEnEventos();
        this.actualizarEventosRutas();
        this.refrescarPosicionesSeccionesRutas();
      }
    });
  }

  public mostrarModalAyuda() {
    let titulo = "IFORMACIÓN DE USO";
    let texto = "<h3>Vista scheduler</h3>";
    texto += "<p>(Descripción sobre lo que se muestra)</p>";

    texto += "<h4>Opciones sobre la gráfica</h4>";
    texto += "<p>(Descripción sobre las opciones)</p>";
    texto += "<h5>Opción de filtrado</h5>";
    texto += "<p>(Descripción de filtrado)</p>";
    texto += "<h5>Opción de zoom</h5>";
    texto += "<p>(Descripción de zoom)</p>";

    texto += "<h4>Opciones sobre las rutas</h4>";
    texto += "<h5>(Hablar sobre generación automática)</h5>";
    texto += "<h5>Opción de creación de rutas</h5>";
    texto += "<p>(Descripción de creación de rutas)</p>";
    texto += "<h5>Opción de edición de rutas</h5>";
    texto += "<p>(Descripción de edición de rutas)</p>";

    texto += "<h4>Opciones sobre los servicios</h4>";
    texto += "<h5>(Introducción breve)</h5>";
    texto += "<h5>Opción de asignación conductor</h5>";
    texto += "<p>(Descripción de asignación conductor)</p>";
    texto += "<h5>Opción de asignación conductor</h5>";
    texto += "<p>(Descripción de asignación conductor)</p>";

    this.swalService.showMessageInfo(titulo, texto);
  }

  public comprobarCambiosEnEventos() {
    let eventosIniciales = this.estadoVistaSinAlterar.getEventos();
    let eventosActuales = this.estadoVistaActual.getEventos();
    let eventosActualesSinModificar: EventoScheduler[] = [];
    let eventosActualesModificados: EventoScheduler[] = [];

    eventosActuales.forEach((eventoActual) => {
      let modificado = eventosIniciales.some((eventoInicial) => {
        return eventoInicial.servicio.id === eventoActual.servicio.id
          && ServicioBll.seHaModificado(eventoInicial.servicio, eventoActual.servicio);
      });

      modificado?
        eventosActualesModificados.push(eventoActual):
        eventosActualesSinModificar.push(eventoActual);
    });

    eventosActualesSinModificar.forEach((evento) => {
      if(evento.opciones.modificado) {
        evento.opciones.modificado = false;
        this.schedulerHelper.updateEvent(evento.id);
      }
    });
    eventosActualesModificados.forEach((evento) => {
      if(!evento.opciones.modificado) {
        evento.opciones.modificado = true;
        this.schedulerHelper.updateEvent(evento.id);
      }
    });
  }

  public mostrarDialogoCambios() {
    let serviciosIniciales = this.estadoVistaSinAlterar.getEventos().map((evento) => evento.servicio);
    let serviciosAlterados = this.estadoVistaActual.getServiciosAlterados();

    let titulo = "SERVICIOS ALTERADOS";
    let texto = "<table class='tabla-dialogo'>";
    texto += '<tr><th>ID SERVICIO</th><th>CAMBIOS</th></tr>';
    serviciosAlterados.forEach((servicioAlterado) => {
      let servicioInicial = serviciosIniciales.find((servicioIni) => servicioIni.id === servicioAlterado.id);
      let cambios = ServicioBll.getMensajesCambios(servicioInicial, servicioAlterado);
      texto += '<tr><td rowspan="' + cambios.length + '">' + servicioAlterado.id + '</td><td>' + cambios[0] + '</td></tr>';

      for(let i = 1; i < cambios.length; i++)
        texto += '<tr><td>' + cambios[i] + '</td></tr>';
    })
    texto += '</table>';

    this.swalService.showMessageInfo(titulo, texto);
  }
}
