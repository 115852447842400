// Entity
import { IConductor } from '../entity/conductor.interface';

export abstract class ConductorBll {

  public static formatToSelectValue(conductor: IConductor): string
  {
    return conductor.codigo + ' - ' + conductor.nombre + ' ' + conductor.apellido1 + ' ' + (conductor.apellido2?
      conductor.apellido2: '');
  }
}
