import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evtc-foot',
  templateUrl: './foot.component.html',
  styleUrls: ['./foot.component.scss']
})
export class FootComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
