import * as moment from "moment";

export abstract class DateUtils {
    private static locale = moment.locale();

    public static getMilisegundosEntreFechas(fecha1: Date, fecha2: Date): number {
        return fecha2.getTime() - fecha1.getTime();
    }

    public static getSegundosEntreFechas(fecha1: Date, fecha2: Date): number {
        return this.getMilisegundosEntreFechas(fecha1, fecha2) / 1000;
    }

    public static getMinutosEntreFechas(fecha1: Date, fecha2: Date): number {
        return this.getSegundosEntreFechas(fecha1, fecha2) / 60;
    }

    public static getHorasEntreFechas(fecha1: Date, fecha2: Date): number {
        return this.getMinutosEntreFechas(fecha1, fecha2) / 60;
    }

    public static sumarMilisegundos(fecha: Date, milisegundos: number) {
        fecha.setMilliseconds(fecha.getMilliseconds() + milisegundos);
    }

    public static sumarSegundos(fecha: Date, segundos: number) {
        this.sumarMilisegundos(fecha, segundos * 1000);
    }

    public static sumarMinutos(fecha: Date, minutos: number) {
        this.sumarSegundos(fecha, minutos * 60);
    }

    public static sumarHoras(fecha: Date, horas: number) {
        this.sumarMinutos(fecha, horas * 60);
    }

    public static formatearDuracionSegundos(segundos: number): string {
        let segundos_finales = segundos;

        var horas = Math.floor(segundos_finales / 3600);
        segundos_finales = segundos_finales % 3600;
        var minutos = Math.floor(segundos_finales / 60);
        segundos_finales = segundos_finales % 60;

        return (horas < 10 ? "0" : "") + horas + ':' +
            (minutos < 10 ? "0" : "") + minutos + ':' +
            (segundos_finales < 10 ? "0" : "") + segundos_finales;
    }

    //TODO GENERALIZAR DISTINTOS FORMATOS
    public static parseStringToDate(fecha: string): Date {
        let dateParts = fecha.split(" ");
        let fechaParts = dateParts[0].split("-");
        let horaParts = dateParts[1].split(":");

        return new Date(
            parseInt(fechaParts[2]),
            parseInt(fechaParts[1]) - 1,
            parseInt(fechaParts[0]),
            parseInt(horaParts[0]),
            parseInt(horaParts[1])
        );
    }

    //TODO REALIZAR SIN USO DE LIBRERIA DHTMLX SHEDULER
    public static parseDateToString(date: Date, formatString?: string): string {
        if(!formatString)
            formatString = 'DD-MM-YYYY H:mm';

        return moment(date).format(formatString);
    }
}