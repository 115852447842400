<div class="form-group col-xs-12 row">
    <label class="form-control-label truncate" for="custom-select-label">{{label}}</label>
    <ng-select class="input-group col-sm-12" name="custom-select-value" [items]="items"
        [multiple]="multiple" [(ngModel)]="selectedItemOrItems"
        (ngModelChange)="ngModelChange()" [placeholder]="placeHolder" [searchFn]="filterOption">

        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{ formatValue(item) }}</span>
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <span>{{ formatOption(item) }}</span>
        </ng-template>

    </ng-select>
</div>