export const AppParameters = {
    companyName: 'Soluciones Técnológicas E-VTC S.L.',
    companyTradeNameDefault:'E-VTC',
    companyNameDefault:'SOLUCIONES TECNOLÓGICAS E-VTC S.L.',
    logoDefault:'assets/icons/logoEVTC-corto.png',
    noImageDefault: 'assets/icons/noImage.png',
    userImageDefault:'assets/icons/anonimous.png', 
};  



