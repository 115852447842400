import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/chronos';

defineLocale('es', esLocale);

@Component({
  selector: 'evtc-custom-timepicker',
  templateUrl: './custom-timepicker.component.html',
  styleUrls: ['./custom-timepicker.component.scss']
})
export class CustomTimepickerComponent {

  @Input()
  public label: string = '';
  @Input()
  public selectedTime: Date = null;
  @Output()
  public onSelectionChanged = new EventEmitter<any>();

  @Output()
  selectedTimeChange = new EventEmitter<Date>();

  public seEstaMostrandoPopup = false;

  @ViewChild('custom_timepicker_popup') customTimepickerPopup: ElementRef<HTMLElement>;
  @ViewChild('custom_timepicker_button') customTimepickerButton: ElementRef<HTMLElement>;
  @ViewChild('custom_timepicker_input') customTimepickerInput: ElementRef<HTMLElement>;

  public ngModelChange() {
    this.selectedTimeChange.emit(this.selectedTime);
    this.onSelectionChanged.emit();
  }

  public showHidePopup() {
    this.seEstaMostrandoPopup = !this.seEstaMostrandoPopup;

    if (this.seEstaMostrandoPopup) {
      let inputRect = this.customTimepickerInput.nativeElement.getBoundingClientRect();

      let newPopupWidth = inputRect.width;

      let newPopupX = 0;
      let newPopupY = 0 + inputRect.height;
      //let newPopupX = inputRect.x;
      //let newPopupY = inputRect.y + inputRect.height;

      this.customTimepickerPopup.nativeElement.style.width = newPopupWidth + 'px';
      this.customTimepickerPopup.nativeElement.style.transform = 'translate3d(' + newPopupX + 'px, ' + newPopupY + 'px, 0)';
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event): void {
      if (!this.customTimepickerPopup.nativeElement.isSameNode(event.target) && !this.customTimepickerPopup.nativeElement.contains(event.target) &&
          !this.customTimepickerButton.nativeElement.isSameNode(event.target) && !this.customTimepickerButton.nativeElement.contains(event.target))
      this.seEstaMostrandoPopup = false;
  }

}
