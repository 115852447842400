import { Injectable, EventEmitter } from '@angular/core';
import { IUser } from '../../core/models/entity/user.interface';
import { ICompany } from '../../core/models/entity/company.interface';

@Injectable({
  providedIn: 'root'
})
export class EmittersService {
  logged$ = new EventEmitter<boolean>();
  title$ = new EventEmitter<string>();
  myUser$ = new EventEmitter<IUser>();
  myCompany$ = new EventEmitter<ICompany>();
  isLogged$ = new EventEmitter<boolean>();
  colorSkin$ = new EventEmitter<string>();
 
  constructor() { }
}
