import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

// Configuration parameters
import { AppConfigParameters } from '../../../assets/config/config';   // Access parameters (nameApp, baseUrlAPI, apiUrl, imagesUrl, ...)

  
@Injectable({
  providedIn: 'root'
})
export class BaseUrlInterceptor implements HttpInterceptor{
  
  constructor() {}
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    const apiReq = req.url.startsWith("http")||req.url.startsWith("https")?req.clone({url:req.url}):req.clone({ url: AppConfigParameters.baseUrlAPI+AppConfigParameters.apiUrl+req.url });    
    return next.handle(apiReq);
  }  

  
}
