import { Injectable, EventEmitter } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap, mapTo } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// Entity
import { IReply } from '../../../core/models/entity/reply.interface';

// Services modules
import { SwalService } from '../../../shared/services/swal.service';
import { EmittersService } from '../../../shared/services/emitters.service';
import { IServicio } from 'src/app/core/models/entity/servicio.interface';
import { ITipoServicio } from 'src/app/core/models/entity/tipo-servicio.interface';
import { IEstadoServicio } from 'src/app/core/models/entity/estado-servicio.interface';
import { IVehiculo } from 'src/app/core/models/entity/vehiculo.interface';
import { IConductor } from 'src/app/core/models/entity/conductor.interface';
import { IAreaTrabajo } from 'src/app/core/models/entity/area-trabajo.interface';
import { ServicioBll } from 'src/app/core/models/business-logic-layer/servicio.bll';

@Injectable({
  providedIn: 'root'
})
export class AsignacionService {

  readonly BASE_URL_SERVICIO = 'servicios';
  readonly URL_ALL_SERVICIOS = this.BASE_URL_SERVICIO + '/all';
  readonly URL_UPDATE_SERVICIOS = this.BASE_URL_SERVICIO + '/update';

  readonly BASE_URL_CONDUCTOR = 'conductores';
  readonly URL_ACTIVED_CONDUCTORES = this.BASE_URL_CONDUCTOR + '/actived';

  readonly BASE_URL_VEHICULO = 'vehiculos';
  readonly URL_ACTIVED_VEHICLES = this.BASE_URL_VEHICULO + '/actived';

  readonly BASE_URL_AREAS_TRABAJO = 'area_trabajo';
  readonly URL_ALL_AREAS_TRABAJO = this.BASE_URL_AREAS_TRABAJO + '/all';

  readonly BASE_URL_TIPOS_SERVICIO = 'tipo_servicio';
  readonly URL_ALL_TIPOS_SERVICIO = this.BASE_URL_TIPOS_SERVICIO + '/all';

  readonly BASE_URL_PERMISOS_CONDUCCION = 'permiso_conduccion';
  readonly URL_ALL_PERMISOS_CONDUCCION = this.BASE_URL_PERMISOS_CONDUCCION + '/all';

  readonly URL_CONFIGURACION = 'configuracion';

  constructor(
    private http: HttpClient,
    private router: Router,
    private messages: SwalService,
    private emitters: EmittersService) {}
  
    getServices(
        inicio: string,
        fin: string,
        estados?: IEstadoServicio[],
        areasTrabajo?: IAreaTrabajo[], 
        tiposServicio?: ITipoServicio[],
        conductores?: IConductor[],
        vehiculos?: IVehiculo[]
    ) : Observable<IReply> {
      let body = {
        inicio: inicio,
        fin: fin,
        codEstados: estados? estados.map((e) => e.codigo): [],
        idAreasTrabajo: areasTrabajo? areasTrabajo.map((at) => at.id): [],
        idTiposServicio: tiposServicio? tiposServicio.map((ts) => ts.id): [],
        idConductores: conductores? conductores.map((c) => c.id): [],
        idVehiculos: vehiculos? vehiculos.map((v) => v.id): [],
        serviciosSinConductor: true
      };
  
      return this.http.post(this.URL_ALL_SERVICIOS, body).pipe(
        tap((resp: IReply) => {
          let servicios = resp.result['servicios'] as IServicio[];

          if(servicios) {
            resp.result['servicios'] = servicios.map((servicio) => {
              ServicioBll.formatearServicioRecibidoDeAPI(servicio);
              return servicio;
            });
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === undefined) {
            return throwError(error);
          }
          else {
            let errorReply: IReply = {
              error: true,
              message: error.message,
              result: error.error
            };

            this.messages.showHttpError(error);
            return of(errorReply);
          }
        })
      );
    }
  
    getConductoresActivos(idAreasTrabajo? : number[]) : Observable<IReply> {
      let body = {
        idAreasTrabajo: idAreasTrabajo
      };

      return this.http.post(this.URL_ACTIVED_CONDUCTORES, body).pipe(
        tap((resp: IReply) => {
  
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === undefined) {
            return throwError(error);
          }
          else {
            let errorReply: IReply = {
              error: true,
              message: error.message,
              result: error.error
            };

            this.messages.showHttpError(error);
            return of(errorReply);
          }
        })
      );
    }
  
    getVehiculosActivos(idAreasTrabajo? : number[]) : Observable<IReply> {
      return this.http.get(this.URL_ACTIVED_VEHICLES).pipe(
        tap((resp: IReply) => {
  
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === undefined) {
            return throwError(error);
          }
          else {
            let errorReply: IReply = {
              error: true,
              message: error.message,
              result: error.error
            };

            this.messages.showHttpError(error);
            return of(errorReply);
          }
        })
      );
    }
  
    getAreasTrabajo() : Observable<IReply> {
      return this.http.get(this.URL_ALL_AREAS_TRABAJO).pipe(
        tap((resp: IReply) => {
  
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === undefined) {
            return throwError(error);
          }
          else {
            let errorReply: IReply = {
              error: true,
              message: error.message,
              result: error.error
            };

            this.messages.showHttpError(error);
            return of(errorReply);
          }
        })
      );
    }
  
    getTiposServicio() : Observable<IReply> {
      return this.http.get(this.URL_ALL_TIPOS_SERVICIO).pipe(
        tap((resp: IReply) => {
  
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === undefined) {
            return throwError(error);
          }
          else {
            let errorReply: IReply = {
              error: true,
              message: error.message,
              result: error.error
            };

            this.messages.showHttpError(error);
            return of(errorReply);
          }
        })
      );
    }
  
    getPermisosConduccion() : Observable<IReply> {
      return this.http.get(this.URL_ALL_PERMISOS_CONDUCCION).pipe(
        tap((resp: IReply) => {
  
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === undefined) {
            return throwError(error);
          }
          else {
            let errorReply: IReply = {
              error: true,
              message: error.message,
              result: error.error
            };

            this.messages.showHttpError(error);
            return of(errorReply);
          }
        })
      );
    }
  
    getConfiguracion() : Observable<IReply> {
      return this.http.get(this.URL_CONFIGURACION).pipe(
        tap((resp: IReply) => {
  
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === undefined) {
            return throwError(error);
          }
          else {
            let errorReply: IReply = {
              error: true,
              message: error.message,
              result: error.error
            };

            this.messages.showHttpError(error);
            return of(errorReply);
          }
        })
      );
    }
  
    putServicios(servicios : IServicio[]) : Observable<IReply> {
      return this.http.post(this.URL_UPDATE_SERVICIOS, servicios).pipe(
        tap((resp: IReply) => {
  
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === undefined) {
            return throwError(error);
          }
          else {
            let errorReply: IReply = {
              error: true,
              message: error.message,
              result: error.error
            };

            this.messages.showHttpError(error);
            return of(errorReply);
          }
        })
      );
    }

}
