<aside class="main-sidebar sidebar-dark-orange elevation-4 sidebar-no-expand" [ngClass]="{
    'sidebar-dark-blue':color==='blue',
    'sidebar-dark-lightblue':color==='lightblue',
    'sidebar-dark-red':color==='red',
    'sidebar-dark-navy':color==='navy',
    'sidebar-dark-green':color==='green',
    'sidebar-dark-teal':color==='teal',
    'sidebar-dark-olive':color==='olive',
    'sidebar-dark-orange':color==='orange',
    'sidebar-dark-yellow':color==='yellow',
    'sidebar-dark-maroon':color==='maroon',
    'sidebar-dark-black':color==='black',
    'sidebar-dark-gray':color==='gray'
  }">
  <!-- Brand Logo -->
  <p class="brand-link">
    <img [src]="logo" alt="logo Company" class="brand-image img-circle elevation-3" style="opacity: .8">
    <b>{{tradeName}}</b>
  </p>
  

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel d-flex">
      <div class="info-box bg-gray-dark">
        <span class="info-box-icon"><img [src]="imageUser" class="img-circle elevation-2" alt="User Image"></span>
        <div class="info-box-content">
          <span class="info-box-text">{{name}}</span>
          <span class="info-box-number">{{role}}</span>
        </div>
      </div>
    </div>
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column nav-legacy" data-widget="treeview" role="menu"
        data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library -->
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="asignacion">
            <p>
              <i class="fas fa-car"></i>
              Asignación
            </p>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="manual">
            <p>
              <i class="fas fa-file-pdf"></i>
              Manual
            </p>
          </a>
        </li>

      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>