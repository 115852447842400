import { Component, OnInit } from '@angular/core';
import { EmittersService } from '../../../shared/services/emitters.service';
import { AuthService } from '../../auth/services/auth.service';
import { SwalService } from '../../../shared/services/swal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'evtc-asignacion-page',
  templateUrl: './asignacion-page.component.html',
  styleUrls: ['./asignacion-page.component.scss']
})
export class AsignacionPageComponent implements OnInit {

  companies:number=0;

  constructor(
    private emittersService: EmittersService,
    private authService: AuthService,
    private messages: SwalService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.emittersService.title$.emit('Página Principal');
    // La siguiente linea es comprobación. No sería necesaria si se solicita algún
    // datos a la api. Este retornaría error de token si así lo fuera.
    this.authService.isJwtAlive().subscribe(resp=>{
      // TO DO SOMETHING
    },error=>{
      this.messages.showHttpError(error);
      this.logout()
    }
    );
  }

   /**
   * Logout and go to login page.
   */
    logout() {
      this.authService.logout();
      this.router.navigate(['auth/login']);
    }
}
