// Aux modules
declare const baseUrlAPI: string;
declare const nameApp: string;
declare const apiUrl: string;
declare const imagesUrl: string;


export const AppConfigParameters = {    
    baseUrlAPI: baseUrlAPI,
    nameApp: nameApp,
    apiUrl: apiUrl,
    imagesUrl: imagesUrl
}
