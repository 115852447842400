// Entity
import { ITipoServicio } from '../entity/tipo-servicio.interface';

export abstract class TipoServicioBll {

  public static formatToSelectValue(tipoServicio: ITipoServicio): string
  {
    return tipoServicio.codigo + ' - ' + tipoServicio.nombre;
  }
}
