import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IServicio } from 'src/app/core/models/entity/servicio.interface';

@Component({
  selector: 'evtc-form-servicio',
  templateUrl: './form-servicio.component.html',
  styleUrls: ['./form-servicio.component.scss']
})
export class FormServicioComponent implements OnInit {

  @Input()
  public servicio: IServicio;
  @Output()
  public servicioChange = new EventEmitter<IServicio>();

  public fechaInicioFormulario: Date;
  public horaInicioFormulario: Date;
  public direccionGoogleOrigen;
  public direccionGoogleDestino;

  public erroresEnFormulario: string[];
  public avisosEnFormulario: string[];

  constructor() {
    this.erroresEnFormulario = [];
    this.avisosEnFormulario = [];
  }

  ngOnInit(): void {
    if(this.servicio) {
      this.fechaInicioFormulario = this.horaInicioFormulario = this.servicio.dateFechaInicio;
      this.direccionGoogleOrigen = {
        nombre: this.servicio.origen,
        latitud: this.servicio.latitudOrigen?? 0,
        longitud: this.servicio.longitudOrigen?? 0,
      };
      this.direccionGoogleDestino = {
        nombre: this.servicio.destino,
        latitud: this.servicio.latitudDestino?? 0,
        longitud: this.servicio.longitudDestino?? 0,
      };
    }
    else {
      this.fechaInicioFormulario = this.horaInicioFormulario = new Date();
      this.direccionGoogleOrigen = null;
      this.direccionGoogleDestino = null;
    }
  }

  public validarFormulario() {
    this.erroresEnFormulario = [];
    this.avisosEnFormulario = [];

    this.validarInicio();
    this.validarOrigenYDestino();
    this.setConfirmButtonDisabled(this.erroresEnFormulario.length > 0);

    this.emitirCambios();
  }

  private validarInicio() {
    if (!this.fechaInicioFormulario) {
      this.erroresEnFormulario.push('Debes introducir una fecha inicio');
    }
    else if (this.fechaInicioFormulario.toString() === 'Invalid Date') {
      this.erroresEnFormulario.push('La fecha inicio es inválida');
    }

    if (!this.horaInicioFormulario) {
      this.erroresEnFormulario.push('Debes introducir una hora inicio');
    }
    else if (this.horaInicioFormulario.toString() === 'Invalid Date') {
      this.erroresEnFormulario.push('La hora inicio es inválida');
    }

    if (this.erroresEnFormulario.length === 0) {
      let fechaHoraInicioNew = new Date(
        this.fechaInicioFormulario.getFullYear(),
        this.fechaInicioFormulario.getMonth(),
        this.fechaInicioFormulario.getDate(),
        this.horaInicioFormulario.getHours(),
        this.horaInicioFormulario.getMinutes()
      );

      this.servicio.dateFechaInicio = fechaHoraInicioNew;
      let ahora = new Date();

      if((fechaHoraInicioNew < ahora)) {
        this.avisosEnFormulario.push('La fecha y hora Origen es anterior');
      }
    }
  }

  private validarOrigenYDestino() {
    if (!this.direccionGoogleOrigen || !this.direccionGoogleOrigen.latitud || !this.direccionGoogleOrigen.longitud) {
      this.erroresEnFormulario.push('Debes seleccionar una direccion de Origen de google');
    }
    if (!this.direccionGoogleDestino || !this.direccionGoogleDestino.latitud || !this.direccionGoogleDestino.longitud) {
      this.erroresEnFormulario.push('Debes seleccionar una direccion de Destino de google');
    }

    if (this.erroresEnFormulario.length === 0) {
      this.servicio.origen = this.direccionGoogleOrigen.nombre;
      this.servicio.latitudOrigen = this.direccionGoogleOrigen.latitud;
      this.servicio.longitudOrigen = this.direccionGoogleOrigen.longitud;

      this.servicio.destino = this.direccionGoogleDestino.nombre;
      this.servicio.latitudDestino = this.direccionGoogleDestino.latitud;
      this.servicio.longitudDestino = this.direccionGoogleDestino.longitud;
    }
  }

  public emitirCambios() {
    this.servicioChange.emit(this.servicio);
  }

  // TODO ACCEDER AL BOTON DESDE SWEET-ALERT-2
  private setConfirmButtonDisabled(disabled: boolean) {
    let botonConfirmar = document.getElementsByClassName('swal2-confirm')[0] as HTMLElement;
    //botonConfirmar.style.display = disabled? 'none': 'inline-block';
    disabled?
      botonConfirmar.setAttribute('disabled', 'true'):
      botonConfirmar.removeAttribute('disabled');
  }
}
