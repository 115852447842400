import { IConductor } from "./entity/conductor.interface";
import { EventoScheduler } from "./helper/dhtmlxHelper/evento-scheduler.class";
import { RutaServicios } from "./business-logic-layer/asignacion-automatica-servicios/ruta-servicios.class";

import * as _ from "lodash";
import { DatosFiltroAsigScheduler } from "./datos-filtro-asig-scheduler.class";
import { IServicio } from "./entity/servicio.interface";

export class EstadoVistaAsig {
    private datosFiltro: DatosFiltroAsigScheduler;
    private rutas: RutaServicios[];
    private conductores:  IConductor[];
    private eventos: EventoScheduler[];
    private titulo: string;
    private fecha: Date;

    public constructor(
        rutas: RutaServicios[],
        conductores:  IConductor[],
        eventos: EventoScheduler[],
        titulo?: string
    ) {
        this.limpiarDatosFiltro();
        this.rutas = rutas;
        this.conductores = conductores;
        this.eventos = eventos;

        if(titulo)
            this.titulo = titulo;

        this.fecha = new Date();
        this.corregirReferencias();
    }

    public static fromJsonObject(objeto: any): EstadoVistaAsig {
        let estado = new EstadoVistaAsig([], [], []);
        estado = Object.assign(estado, objeto);
        estado.setRutas(estado.getRutas().map((ruta) => Object.assign(new RutaServicios(0), ruta)));
        estado.setEventos(estado.getEventos().map((evento) => Object.assign(new EventoScheduler(evento.servicio, evento.section_id, evento.text, evento.opciones, evento.estado), evento)));
        estado.corregirReferencias();

        return estado;
    }

    public corregirReferencias() {
        let evetosScheduler = this.getEventos();
        this.rutas.forEach((ruta) => {
            let serviciosReferenciados = ruta.getServicios().map((servicio) => 
                evetosScheduler.find((eventoScheduler) => eventoScheduler.servicio.id === servicio.id).servicio
            );
            ruta.setServicios(serviciosReferenciados);
        });
    }

    public getDatosFiltro(): DatosFiltroAsigScheduler {
        return this.datosFiltro;
    }

    public setDatosFiltro(datosFiltro: DatosFiltroAsigScheduler) {
        this.datosFiltro = datosFiltro;
    }

    public getRutas(): RutaServicios[] {
        return this.rutas;
    }

    public setRutas(rutas: RutaServicios[]) {
        this.rutas = rutas;
    }

    public getConductores(): IConductor[] {
        return this.conductores;
    }

    public setConductores(conductores: IConductor[]) {
        this.conductores = conductores;
    }

    public getEventos(): EventoScheduler[] {
        return this.eventos;
    }

    public setEventos(eventos: EventoScheduler[]) {
        this.eventos = eventos;
    }

    public getTitulo(): string {
        return this.titulo;
    }

    public setTitulo(titulo: string) {
        this.titulo = titulo;
    }

    public getFecha(): Date {
        return this.fecha;
    }

    public limpiarDatosFiltro() {
        this.datosFiltro = new DatosFiltroAsigScheduler();
    }

    public limpiarDatosScheduler() {
        this.rutas = [];
        this.conductores = [];
        this.eventos = [];
    }

    public clonar() {
        return _.cloneDeep(this);
    }

    public capturarEstado(nuevoTitulo?: string) {
        let clone = this.clonar();
        clone.fecha = new Date();

        if(nuevoTitulo)
            clone.titulo = nuevoTitulo;
            
        return clone;
    }

    public getEventosAlterados(): EventoScheduler[] {
        return this.getEventos().filter((e) => e.opciones.modificado === true);
    }

    public getServiciosAlterados(): IServicio[] {
        return this.getEventosAlterados()
            .map((evento) => evento.servicio)
            .filter((servicio, indice, self) => { // Eliminamos duplicados por proyecciones
                return self.findIndex((servicioBusqueda) => servicioBusqueda.id === servicio.id) === indice;
            });
    }
}