export abstract class JsonUtils {

  private static retieverParseJson(clave, valor) {
    let valorFinal = valor;
    let regexDate = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(Z|([+\-])(\d{2}):(\d{2}))$/;

    if (typeof valor === 'string' && regexDate.test(valor)) {
      valorFinal = new Date(valor);
    }

    return valorFinal;
  }

  public static fromJson(json: string): any {
    return JSON.parse(json, this.retieverParseJson);
  }

  public static toJson<T>(obj: T): string {
    return JSON.stringify(obj);
  }
}