import { IAreaTrabajo } from "../../entity/area-trabajo.interface";
import { IConductor } from "../../entity/conductor.interface";
import { IServicio } from "../../entity/servicio.interface";
import { IPermisoConduccion } from "../../entity/permiso-conduccion.interface";
import { ServicioBll } from "../servicio.bll";
import { IVehiculo } from "../../entity/vehiculo.interface";

export class RutaServicios {
    private id: number;
    private servicios: IServicio[];
    private conductorAsignado: IConductor | null;
    private areaTrabajo: IAreaTrabajo;
    private asignacionConfirmada: boolean;

    public constructor(id: number, servicios?: IServicio[], conductor?: IConductor) {
        this.id = id;
        this.asignacionConfirmada = false;
        this.setServicios(servicios ? servicios : []);
        this.setConductor(conductor ? conductor : null);
    }

    public static fromJsonObject(objeto: any): RutaServicios {
        let ruta = new RutaServicios(objeto.id);
        Object.assign(ruta, objeto);

        return ruta;
    }

    public getServicios(): IServicio[] {
        return this.servicios;
    }

    public setServicios(servicios: IServicio[]): void {
        if (servicios.every((servicio) => this.esCompatibleConServicio(servicio))) {
            this.servicios = servicios;
            this.actualizarAreaTrabajo();
            this.actualizarConductoresServicios();
        }
    }

    public addServicio(servicio: IServicio): void {
        if (this.esCompatibleConServicio(servicio)) {
            this.servicios.push(servicio);
            this.actualizarAreaTrabajo();
            this.actualizarConductoresServicios();
        }
    }

    public removeServicio(idServicio: number): void {
        let index = this.servicios.findIndex((s) => s.id === idServicio);
        if (index >= 0) {
            this.servicios[index].idConductores = [];
            this.servicios.splice(index, 1);
            this.actualizarAreaTrabajo();
        }
    }

    public getConductor(): IConductor | null {
        return this.conductorAsignado;
    }

    public setConductor(conductor: IConductor): void {
        if (!conductor || this.esCompatibleConConductor(conductor)) {
            this.conductorAsignado = conductor;

            if (!conductor)
                this.desconfirmarAsignacion();

            this.actualizarAreaTrabajo();
            this.actualizarConductoresServicios();
        }
    }

    public confirmarAsignacion() {
        this.asignacionConfirmada = true;
        this.actualizarConductoresServicios();
    }

    public desconfirmarAsignacion() {
        this.asignacionConfirmada = false;
        this.actualizarConductoresServicios();
    }

    public getConfirmacionAsignacion(): boolean {
        return this.asignacionConfirmada;
    }

    public getAreaTrabajo(): IAreaTrabajo | null {
        return this.areaTrabajo;
    }

    public getPermisoConduccionMax(): IPermisoConduccion | null {
        let permisoConduccionMax: IPermisoConduccion = null;

        if (this.servicios.length > 0) {
            permisoConduccionMax = this.servicios.reduce((prevServ, actServ) =>
                prevServ.tipoServicio.permisoConduccion.nivel > actServ.tipoServicio.permisoConduccion.nivel ? prevServ : actServ
            ).tipoServicio.permisoConduccion;
        }

        return permisoConduccionMax;
    }

    private actualizarAreaTrabajo() {
        if (this.servicios.length > 0)
            this.areaTrabajo = this.servicios[0].areaTrabajo;
        else if (this.conductorAsignado)
            this.areaTrabajo = this.conductorAsignado.areaTrabajo;
        else
            this.areaTrabajo = null;
    }

    private actualizarConductoresServicios() {
        if (this.conductorAsignado && this.asignacionConfirmada)
            this.servicios.forEach((servicio) => servicio.idConductores = [this.conductorAsignado.id]);
        else
            this.servicios.forEach((servicio) => servicio.idConductores = []);
    }

    public getId(): number {
        return this.id;
    }

    public isEmpty(): boolean {
        return this.servicios.length === 0;
    }

    private existeServicioSolapadoEnHorario(servicio: IServicio): boolean {
        return this.servicios.some(servicioRuta => ServicioBll.serviciosSolapadosEnHorario(servicio, servicioRuta) === true);
    }

    public sePuedeAsignarAutomaticamenteServicio(servicio: IServicio): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();
        let conductorRuta = this.getConductor();

        if (areaTrabajoRuta && areaTrabajoRuta.id !== servicio.areaTrabajo.id)
            esCompatible = false;
        else if (conductorRuta && conductorRuta.permisoConduccion.nivel < servicio.tipoServicio.permisoConduccion.nivel)
            esCompatible = false;
        else if (this.existeServicioSolapadoEnHorario(servicio))
            esCompatible = false;

        return esCompatible;
    }

    public esCompatibleConServicio(servicio: IServicio): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();
        let conductorRuta = this.getConductor();

        if (areaTrabajoRuta && areaTrabajoRuta.id !== servicio.areaTrabajo.id)
            esCompatible = false;
        else if (conductorRuta && conductorRuta.permisoConduccion.nivel < servicio.tipoServicio.permisoConduccion.nivel)
            esCompatible = false;

        return esCompatible;
    }

    public esCompatibleConVehiculo(vehiculo: IVehiculo): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();

        
        if(areaTrabajoRuta && areaTrabajoRuta.id !== vehiculo.areaTrabajo.id)
            esCompatible = false;
        else if(!this.getServicios().every(((servicio) => ServicioBll.esCompatibleConVehiculo(servicio, vehiculo))))
            esCompatible = false;

        return esCompatible;
    }

    public esCompatibleConConductor(conductor: IConductor): boolean {
        let esCompatible = true;
        let areaTrabajoRuta = this.getAreaTrabajo();

        if (areaTrabajoRuta && areaTrabajoRuta.id !== conductor.areaTrabajo.id)
            esCompatible = false;
        else if (!this.getServicios().every((servicio) => ServicioBll.esCompatibleConConductor(servicio, conductor)))
            esCompatible = false;

        return esCompatible;
    }
}
