import { Component, OnInit } from '@angular/core';

// Configuration parameters
import { AppConfigParameters } from '../../../../assets/config/config';   // Access parameters (nameApp, baseUrlAPI, apiUrl, imagesUrl, ...)
import { AppParameters } from '../../../core/constants/parameters';     // Config parameters App (companyName,companyTradeNameDefault,companyNameShort,logoDefault,noImageDefault,userImageDefault,..)



// Services imports
import { EmittersService } from '../../services/emitters.service';

// Models 
import { ICompany } from '../../../core/models/entity/company.interface';

@Component({
  selector: 'evtc-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  name: string = "";
  imageUser: string = null;
  color: string = 'black';
  tradeName: string = '';
  logo: string = null;
  role: string = null;

  constructor(
    private emittersService: EmittersService
  ) { }

  ngOnInit(): void {
    this.emittersService.myUser$.subscribe(user => {
      this.name = user.username;
      this.imageUser = user.foto;
      this.role = user.role;
    });
    this.emittersService.myCompany$.subscribe(company => {
      let myCompany: ICompany = company;
      this.tradeName = company.nombre_comercial;
      this.logo = myCompany.url_logo || AppParameters.logoDefault;
    })

  }
}