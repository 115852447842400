// Entity

import { IVehiculo } from "../entity/vehiculo.interface";

export abstract class VehiculoBll {

  public static formatToSelectValue(item: IVehiculo): string
  {
    return item.codigo + ' - ' + item.matricula + ' (' + item.alias.substr(0, 5) + ')';
  }
}
