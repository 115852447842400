import { Injectable, EventEmitter } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap, mapTo } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// Entity
import { IReply } from '../../../core/models/entity/reply.interface';
import { IUser } from '../../../core/models/entity/user.interface';
import { Token } from '../../../core/models/token.class';
import { ICompany } from '../../../core/models/entity/company.interface';


// Configuration parameters
import { AppParameters } from '../../../core/constants/parameters';   // Config parameters App (companyName,companyTradeNameDefault,companyNameShort,logoDefault,noImageDefault,userImageDefault,..)


// Services modules
import { SwalService } from '../../../shared/services/swal.service';
import { EmittersService } from '../../../shared/services/emitters.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  readonly BASE_URL_AUTH = 'auth';
  readonly BASE_URL_USER = 'user';
  readonly BASE_URL_COMPANY = 'company';
  readonly LOGIN_URL = this.BASE_URL_AUTH+'/login';
  readonly VALIDATE_TOKEN_URL = this.BASE_URL_AUTH+'/validate';
  readonly ME_URL = this.BASE_URL_USER+'/me';
  readonly ME_COMPANY_URL = this.BASE_URL_COMPANY+'/';
  
  logged: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private messages: SwalService,
    private emitters: EmittersService) {  }


  private getToken(): Token {
    let userData: string = localStorage.getItem("userdata");
    let dataObj = JSON.parse(userData);
    return (userData !== null ? dataObj : null);
  }

  private setLogged(isLogged: boolean) {
    this.logged=isLogged;
    this.emitters.logged$.emit(isLogged);
  }

  private doLoginUser(token: Token) {
    this.setLogged(true);
    localStorage.setItem("userdata", JSON.stringify(token));
  }

  private doLogoutUser() {  
    localStorage.removeItem("userdata");
    this.setLogged(false);
  }

  /* 
    PUBLIC FUNCTIONS
  */


  /**
   * Get token
   * @returns 
   */
  getJwtToken(): string {
    let token: Token = this.getToken();
    return (token===null?null:token.jwt);
  }


 /**
   * Is token alive?
   * @returns 
   */
  isJwtAlive(): Observable<boolean> {
    return this.http.get(this.VALIDATE_TOKEN_URL).pipe(
      map((resp: IReply) => {
        if (!this.logged) this.setLogged(true);
        return true;
      }),
      catchError((resp: HttpErrorResponse) => {
        return throwError(resp);
      })
    );
  }


  /**
   * Am I logged?
   * @returns 
   */
  isLogged(): Observable<boolean> {
    if (!this.logged) {
      if (this.getJwtToken()) {
        return this.http.get(this.VALIDATE_TOKEN_URL).pipe(
          map((resp: IReply) => {
            this.setLogged(true);
            return true;
          }),
          catchError((resp: HttpErrorResponse) => {
            return throwError(resp);
          })
        );
      } else {
        return of(false);
      }
    } else {
      return of(true);
    }
  }


  /**
   * Login method
   * @param user User
   * @returns 
   */
  login(user: IUser): Observable<IReply> {
    return this.http.post(this.LOGIN_URL, user).pipe(
      tap((resp: IReply) => {
        let token = new Token();
        token.jwt = resp.result['token'];
        this.doLoginUser(token);
      }),
      mapTo(true),
      catchError((error: HttpErrorResponse) => {
        if (error.error.result===undefined){
          return throwError(error) // If error isnt defined
        }else{
          return of(error.error);
        }
      }));
  }

  /**
   * Get user profile
   */
   getProfile(): Observable<IUser> {
    return this.http.get(this.ME_URL)
      .pipe(
        map((resp:IReply) => {
          const u: IUser = resp.result;
          if (u.foto === null) {
            u.foto = AppParameters.userImageDefault;
          } else {
            u.foto = u.foto;
          }
          return u;
        }),
        catchError((resp: HttpErrorResponse) => throwError(resp))
      );
  }


  /**
   * Get company
   * @returns 
   */
   getCompany(): Observable<ICompany> {
    return this.http.get(this.ME_COMPANY_URL )
      .pipe(
        map((company:ICompany) => {
          if (company.nombre ===undefined || company.nombre===null) company.nombre=AppParameters.companyNameDefault;
          if (company.nombre_comercial ===undefined || company.nombre_comercial===null) company.nombre_comercial=AppParameters.companyTradeNameDefault;
          return company;
        })
      );
  }   

  
  /**
   * Logout method
   */
  logout() {
    this.doLogoutUser();
    this.router.navigate(['login']);
  }


}