import { Component, EventEmitter, Input, Output } from '@angular/core';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/chronos';

defineLocale('es', esLocale);

@Component({
  selector: 'evtc-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss']
})
export class CustomDatepickerComponent {

  @Input()
  public label: string = '';
  @Input()
  public selectedDate: Date = null;
  @Output()
  public onSelectionChanged = new EventEmitter<any>();

  @Output() selectedDateChange = new EventEmitter<Date>();
  
  public ngModelChange() {
    this.selectedDateChange.emit(this.selectedDate);
    this.onSelectionChanged.emit();
  }

}
