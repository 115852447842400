import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FootComponent } from './foot/foot.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [FootComponent, NavigationBarComponent, SideMenuComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    SideMenuComponent, 
    NavigationBarComponent,
    FootComponent
  ]
})
export class DashboardModule { }
