// Entity
import { IConductor } from '../entity/conductor.interface';
import { IConfiguracion } from '../entity/configuracion.interface';
import { IServicio } from '../entity/servicio.interface';
import { IVehiculo } from '../entity/vehiculo.interface';
import { DateUtils } from '../utils/date.util';
import { RutaServicios } from './asignacion-automatica-servicios/ruta-servicios.class';

export class ServicioBll {

  public static readonly VALUE_NO_SALIDA_LLEGADA = 0;
  public static readonly VALUE_ES_SALIDA = 1;
  public static readonly VALUE_ES_LLEGADA = 2;

  constructor() {  }

  /**
   * Devuelve la distancia en kilomegtros entre dos puntos dados por su latitud y longitud
   *
   * @param latitud1 Latitud del punto 1
   * @param longitud1 Longitud del punto 1
   * @param latitud2 Latitud del punto 2
   * @param longitud2 Longitud del punto 2
   *
   * @returns Distancia en kilometros
   *
   **/
  public static getKilometrosEntreCoordenadas(latitud1: number, longitud1: number, latitud2: number, longitud2: number): number
  {
    let rad = (grados) => grados * Math.PI / 180;
    let radioTierraKm = 6378.137;
    let diferenciaLatitud = rad(latitud2 - latitud1);
    let diferenciaLongitud = rad(longitud2 - longitud1);

    let a = Math.sin(diferenciaLatitud / 2) * Math.sin(diferenciaLatitud / 2) + Math.cos(rad(latitud1)) * Math.cos(rad(latitud2)) * Math.sin(diferenciaLongitud / 2) * Math.sin(diferenciaLongitud / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = radioTierraKm * c;

    return d;
  }

  public static getEstimacionMinutosPorKilometroDistanciaEntreServicios(servicio1: IServicio, servicio2: IServicio): number
  {
    if(servicio1.latitudDestino && servicio1.longitudDestino && servicio2.latitudOrigen && servicio2.longitudOrigen) {
      return this.getKilometrosEntreCoordenadas(
        servicio1.latitudDestino, servicio1.longitudDestino,
        servicio2.latitudOrigen, servicio2.longitudOrigen
      ) * servicio1.tipoServicio.estimacionMinutosPorKilometroDistanciaEnLineaRecta;
    }

    return 0;
  }

  public static getPrefijoTipo(servicio: IServicio): string
  {
    let prefijo = '';

    switch (servicio.tipo) {
        case 'T':
          prefijo = 'T';
          if(servicio.esSalida === this.VALUE_ES_LLEGADA)
            prefijo += '-LL';
          else if(servicio.esSalida === this.VALUE_ES_SALIDA)
            prefijo += '-S';
          break;
        case 'D': prefijo = 'D'; break;
        case 'K': prefijo = 'TPD'; break;
    }

    return prefijo;
  }

  public static getNombreTipo(servicio: IServicio): string
  {
    let tipo = '';

    switch (servicio.tipo) {
        case 'T': tipo = 'TRASLADO'; break;
        case 'D': tipo = 'DISPOSICIÓN'; break;
        case 'K': tipo = 'TRASLADO POR DISTANCIA'; break;
    }

    return tipo;
  }

  public static getMinutosEntreServicios = function(servicio1, servicio2)
  {
    // TODO UtilsDate
    return (servicio2.dateFechaInicio.getTime() - servicio1.dateFechaFinConMargen.getTime()) / 1000 / 60;
  }

  public static serviciosSolapadosEnHorario(servicio1: IServicio, servicio2: IServicio): boolean {

    let estanSolapados = (servicio1.dateFechaInicio >= servicio2.dateFechaInicio && servicio1.dateFechaInicio <= servicio2.dateFechaFinConMargen)
            ||
        (servicio1.dateFechaFinConMargen >= servicio2.dateFechaInicio && servicio1.dateFechaFinConMargen <= servicio2.dateFechaFinConMargen)
            ||
        (servicio2.dateFechaInicio >= servicio1.dateFechaInicio && servicio2.dateFechaInicio <= servicio1.dateFechaFinConMargen)
            ||
        (servicio2.dateFechaFinConMargen >= servicio1.dateFechaInicio && servicio2.dateFechaFinConMargen <= servicio1.dateFechaFinConMargen);

    if (!estanSolapados) {
      let servicioAnterior = servicio1;
      let servicioPosterior = servicio2;

      if(servicio1.dateFechaFinConMargen > servicio2.dateFechaInicio) {
        servicioAnterior = servicio2;
        servicioPosterior = servicio1;
      }

      let minutosEntreServicios = this.getMinutosEntreServicios(servicioAnterior, servicioPosterior);
      let minutosEstimadosEntreServicios = this.getEstimacionMinutosPorKilometroDistanciaEntreServicios(servicioAnterior, servicioPosterior);

      estanSolapados = minutosEstimadosEntreServicios > minutosEntreServicios;
    }

    return estanSolapados;
  }

  public static esCompatibleConConductor(servicio: IServicio, conductor: IConductor): boolean {
    let esCompatible = true;

    if (conductor.areaTrabajo.id !== servicio.areaTrabajo.id)
      esCompatible = false;
    else if (conductor.permisoConduccion.nivel < servicio.tipoServicio.permisoConduccion.nivel)
      esCompatible = false;
    else if (servicio.idioma && !conductor.idsIdiomas.some((idIdioma) => idIdioma === servicio.idioma.id))
      esCompatible = false;
    
    return esCompatible;
  }

  public static esCompatibleConVehiculo(servicio: IServicio, vehiculo: IVehiculo): boolean {
    let esCompatible = true;

    if (vehiculo.areaTrabajo.id !== servicio.areaTrabajo.id)
      esCompatible = false;
    else if (vehiculo.permisoConduccion.nivel < servicio.tipoServicio.permisoConduccion.nivel)
      esCompatible = false;
    else if (!vehiculo.idsTiposServicioCompatibles.some((idTiposServicioCompatible) => idTiposServicioCompatible === servicio.tipoServicio.id))
      esCompatible = false;
    else if (vehiculo.numPlazas < (servicio.numeroAdultos + servicio.numeroNinyos + servicio.numeroBebes))
      esCompatible = false;
    else if (servicio.color && (!vehiculo.color || vehiculo.color.id != servicio.color.id))
      esCompatible = false;
    
    return esCompatible;
  }

  public static formatearServicioRecibidoDeAPI(servicio: IServicio) {
    servicio.dateFechaInicio = DateUtils.parseStringToDate(servicio.fechaInicio);
    this.sincronizarFechasConInicioYMargenes(servicio);
  }

  public static calcularMargenes(servicio: IServicio, configuracion: IConfiguracion) {
    servicio.margenSegundosInicial = 0;
    servicio.margenSegundosFinal = servicio.tipoServicio.minimoMinutosEntreServicios * 60;

    if(servicio.esSalida === this.VALUE_ES_LLEGADA)
      servicio.margenSegundosInicial += configuracion.margenMinutosInicialesLlegadaVuelo * 60;

    this.sincronizarFechasConInicioYMargenes(servicio);
  }

  public static sincronizarFechasString(servicio: IServicio) {
    servicio.fechaInicio = DateUtils.parseDateToString(servicio.dateFechaInicio);
    servicio.fechaFin = DateUtils.parseDateToString(servicio.dateFechaFin);
  }

  public static sincronizarFechasConInicioYMargenes(servicio: IServicio) {
    this.sincronizarFechasString(servicio);

    let newDateFin = new Date(servicio.dateFechaInicio);
    DateUtils.sumarSegundos(newDateFin, servicio.duracionSegundos);
    
    servicio.dateFechaFin = newDateFin;

    servicio.dateFechaFinConMargen = new Date(servicio.dateFechaFin);
    DateUtils.sumarSegundos(servicio.dateFechaFinConMargen, servicio.margenSegundosInicial + servicio.margenSegundosFinal);
  }

  public static tienenDireccionesGoogleMaps(servicio: IServicio) {
    return servicio.latitudOrigen && servicio.longitudOrigen && servicio.latitudDestino && servicio.longitudDestino;
  }

  public static seHaModificado(servicioOld: IServicio, servicioNew: IServicio) {
    // Comprobamos campos editables desde la vista de edición
    let modificado = servicioOld.dateFechaInicio.getTime() !== servicioNew.dateFechaInicio.getTime()
    || servicioOld.origen !== servicioNew.origen
    || servicioOld.latitudOrigen !== servicioNew.latitudOrigen
    || servicioOld.longitudOrigen !== servicioNew.longitudOrigen
    || servicioOld.destino !== servicioNew.destino
    || servicioOld.latitudDestino !== servicioNew.latitudDestino
    || servicioOld.longitudDestino !== servicioNew.longitudDestino;

    // Comprobamos asignaciones de vehiculo y conductor
    if(!modificado) {
      modificado = servicioOld.vehiculo?.id !== servicioNew.vehiculo?.id
      || servicioOld.idConductores.length !== servicioNew.idConductores.length
      || servicioOld.idConductores.some((idConductorOld) => servicioNew.idConductores.some((idConductorNew) => idConductorNew !== idConductorOld));
    }

    return modificado;
  }

  public static getMensajesCambios(servicioOld: IServicio, servicioNew: IServicio): string[] {
    let cambios: string[] = [];
    // Comprobamos campos editables desde la vista de edición
    if (servicioOld.dateFechaInicio.getTime() !== servicioNew.dateFechaInicio.getTime())
      cambios.push('Cambio Fecha inicio');
    if (servicioOld.origen !== servicioNew.origen
        || servicioOld.latitudOrigen !== servicioNew.latitudOrigen
        || servicioOld.longitudOrigen !== servicioNew.longitudOrigen)
      cambios.push('Cambio Origen');
    if (servicioOld.destino !== servicioNew.destino
        || servicioOld.latitudDestino !== servicioNew.latitudDestino
        || servicioOld.longitudDestino !== servicioNew.longitudDestino)
      cambios.push('Cambio Destino');

    // Comprobamos asignaciones de vehiculo y conductor
    if (servicioOld.vehiculo?.id !== servicioNew.vehiculo?.id) {
      if(!servicioOld.vehiculo)
        cambios.push('Asignación Vehículo');
      else if(!servicioNew.vehiculo)
        cambios.push('Eliminación Vehículo');
      else
        cambios.push('Reasignación Vehículo');
    }
    if (servicioOld.idConductores.length !== servicioNew.idConductores.length
        || servicioOld.idConductores.some((idConductorOld) => servicioNew.idConductores.some((idConductorNew) => idConductorNew !== idConductorOld))) {
      if (servicioOld.idConductores.length === 0)
        cambios.push('Asignación Conductor');
      else if(servicioNew.idConductores.length === 0)
        cambios.push('Eliminación Conductor');
      else
        cambios.push('Reasignación Conductor');
    }

    return cambios;
  }

  public static getIconosDatosServicio(servicio: IServicio, formatoLargo: boolean = true): string[] {
    let iconos: string[] = [];

    /* if (event.opciones.modificado)
      plantilla += "<i class='fas fa-pen icono-dato-servicio'></i>"; */

    if (formatoLargo && servicio.idConductores.length > 0)
      iconos.push("<i class='fas fa-user icono-dato-servicio'></i>");
    else if (formatoLargo)
      iconos.push("<i class='fas fa-user icono-dato-servicio prohibido'></i>");

    if (servicio.vehiculo)
      iconos.push("<i class='fas fa-car icono-dato-servicio'></i>");
    else if (formatoLargo)
      iconos.push("<i class='fas fa-car icono-dato-servicio prohibido'></i>");

    if (!this.tienenDireccionesGoogleMaps(servicio))
      iconos.push("<i class='fab fa-google icono-dato-servicio prohibido'></i>");
    else if (formatoLargo)
      iconos.push("<i class='fab fa-google icono-dato-servicio'></i>");

    return iconos;
  }
}
