import { Component } from '@angular/core';
import { EmittersService } from './shared/services/emitters.service';

@Component({
  selector: 'evtc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  login: boolean = false;

  constructor(
    private emittersService: EmittersService
  ) {
    this.emittersService.logged$.subscribe(isOk => {
      this.login = isOk;
    });
  }
}