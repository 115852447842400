import { HttpErrorResponse } from '@angular/common/http';
import { ComponentFactoryResolver, Injectable, Injector, NgModuleRef, ViewContainerRef } from '@angular/core';
import { cloneDeep } from 'lodash';
import { IServicio } from 'src/app/core/models/entity/servicio.interface';
import { JsonUtils } from 'src/app/core/models/utils/json.util';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import { CustomComponentsModule } from '../components/custom-components.module';
import { CustomSelectComponent } from '../components/custom-select/custom-select.component';
import { FormServicioComponent } from '../components/form-servicio/form-servicio.component';

@Injectable({
  providedIn: 'root'
})

export class SwalService {

  static readonly CLASS_MODAL_ANCHO = 'swal2-container-ancho';
  static readonly CLASS_MODAL_OVERFLOW_VISIBLE = 'swal2-container-overflow-visible';

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  private showModalBase(
    icon: SweetAlertIcon|null,
    title: string = '',
    html: string = null,
    classList: string[] = [],
    showConfirmButton: boolean = true,
    showCancelButton: boolean = true,
    confirmButtonText: string = "Aceptar",
    cancelButtonText: string = 'Cancelar'
  ) {
    classList.push('swal2-container-custom');
    return Swal.fire({
      customClass: classList.join(' '),
      icon: icon,
      title: title,
      html: html,
      showConfirmButton: showConfirmButton,
      confirmButtonText: confirmButtonText,
      showCancelButton: showCancelButton,
      cancelButtonText: cancelButtonText
    });
  }

  async showModalWithSelect<T>(
      icon: SweetAlertIcon|null,
      title: string,
      text: string,
      viewContainerRef: ViewContainerRef,
      items: T[],
      multiple: boolean = false,
      placeHolder: string = 'N/A',
      initialSelectedIndexes?: number[],
      formatValue?: (item: T) => string,
      formatOption?: (item: T) => string
  ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<CustomSelectComponent<T>>(CustomSelectComponent);
    const componentRef = viewContainerRef.createComponent(componentFactory);
    const customSelectComponent = componentRef.instance;
    customSelectComponent.items = items;
    customSelectComponent.label = text;
    customSelectComponent.multiple = multiple;
    customSelectComponent.placeHolder = placeHolder;

    if(initialSelectedIndexes && initialSelectedIndexes.length > 0) {
        customSelectComponent.selectedItemOrItems = multiple?
          initialSelectedIndexes.map((index) => items[index]):
          items[initialSelectedIndexes[0]];
    }

    if(formatValue)
      customSelectComponent.formatValue = formatValue;
    if(formatOption)
      customSelectComponent.formatOption = formatOption;
    
    let respuesta = null as SweetAlertResult;

    await this.showModalBase(icon, title, componentRef.location.nativeElement, [SwalService.CLASS_MODAL_OVERFLOW_VISIBLE])
      .then((res) => {
        let respuestaEditable = JsonUtils.fromJson(JsonUtils.toJson(res));
        respuestaEditable.value = customSelectComponent.selectedItemOrItems;
        respuesta = respuestaEditable;
    }).finally(() => componentRef.destroy());

    return respuesta;
  }

  async showModalModifyService(servicioMod: IServicio, viewContainerRef: ViewContainerRef) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<FormServicioComponent>(FormServicioComponent);
    const componentRef = viewContainerRef.createComponent(componentFactory);
    const customSelectComponent = componentRef.instance;
    customSelectComponent.servicio = servicioMod;
    
    let respuesta = null as SweetAlertResult;

    await this.showModalBase(
        null,
        'EDICIÓN SERVICIO ' + servicioMod.id,
        componentRef.location.nativeElement,
        [SwalService.CLASS_MODAL_ANCHO, SwalService.CLASS_MODAL_OVERFLOW_VISIBLE]
    ).then((res) => {
        let respuestaEditable = JsonUtils.fromJson(JsonUtils.toJson(res));
        respuestaEditable.value = customSelectComponent.servicio;
        respuesta = respuestaEditable;
    }).finally(() => componentRef.destroy());

    return respuesta;
  }

  /**
   * Show info message
   * @param title Title
   */
  showMessageInfo(title: string, text?: string) {
    return this.showModalBase('info', title, text, [], true, false);
  }

  /**
   * Show warning message
   * @param text Text content
   */
  showMessageError(title: string, text?: string) {
    return this.showModalBase('error', title, text, [], true, false);
  }

  /**
   * Show select message
   * @param title       Title
   * @param options     Options array 
   * @param content Placeholder
   */
  showMessageQuestion(title: string, content?: string, confirmButtonText?: string, cancelTxtBtn?: string) {
    return this.showModalBase('info', title, content, [], true, true, confirmButtonText, cancelTxtBtn);
  }

  /**
   * Show errors messages 
   * @param error 
   */
  showHttpError(error: HttpErrorResponse) {
    let errorStatus: number = (error.status === undefined) ? 9999 : error.status;
    switch (errorStatus) {
      case 400:
      case 401:
      case 403: this.showMessageError("Sesión expirada.");
        break;
      default: this.showMessageError("Error indefinido.");
        break;
    }
  }
}

