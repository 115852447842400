import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
  { path: 'asignacion', loadChildren: () => import('./modules/asignacion/asignacion.module').then(m => m.AsignacionModule)},
  { path: 'manual', loadChildren:  () => import('./modules/manual/manual.module').then(m => m.ManualModule)},
  { path: '', redirectTo: '/auth/login', pathMatch: 'full'},
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
