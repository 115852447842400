import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginActivatedGuard } from '../../core/guards/login-activated-guard';
import { AsignacionAutomaticaComponent } from './asignacion-automatica/asignacion-automatica.component';
import { AsignacionConductoresComponent } from './asignacion-conductores/asignacion-conductores.component';
import { AsignacionPageComponent } from './asignacion-page/asignacion-page.component';


const routes: Routes = [
  { 
    path: '', 
    canActivate: [LoginActivatedGuard],
    component:  AsignacionPageComponent
  },
  { 
    path: 'conductores', 
    canActivate: [LoginActivatedGuard],
    component:  AsignacionConductoresComponent
  },
  { 
    path: 'automatica', 
    canActivate: [LoginActivatedGuard],
    component:  AsignacionAutomaticaComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AsignacionRoutingModule { }