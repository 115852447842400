import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EmittersService } from '../../services/emitters.service';
import { AuthService } from '../../../modules/auth/services/auth.service';

// Configuration parameters
import { AppConfigParameters } from '../../../../assets/config/config';   // Access parameters (nameApp, baseUrlAPI, apiUrl, imagesUrl, ...)
import { AppParameters } from '../../../core/constants/parameters';     // Config parameters App (companyName,companyTradeNameDefault,companyNameShort,logoDefault,noImageDefault,userImageDefault,..)


// Aux modules
import { SwalService } from '../../services/swal.service';

@Component({
  selector: 'evtc-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
   // Colors
   colors: {} =
   {
     'skin-blue': 'blue'
   };
  login: boolean = false;
  color: string = 'gray';
  companyName: string = '';
  constructor(
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    private emittersService: EmittersService,
    private messages: SwalService
  ) {

  }

  ngOnInit(): void {
    this.emittersService.title$.subscribe(texto => {
      this.titleService.setTitle(AppConfigParameters.nameApp + ' - ' + texto);
    });

    this.authService.getProfile().subscribe(usu => {
      this.emittersService.myUser$.emit(usu);
      this.color = this.colors[usu.colorSkin]||'gray';
      this.emittersService.colorSkin$.subscribe(color => {
        this.color = color;
      });
    },error=>{
      this.messages.showHttpError(error);
      this.logout();
    });
    
    this.authService.getCompany().subscribe(company => {
      this.companyName = company.nombre;
      this.emittersService.myCompany$.emit(company);
    });
  }


  logout() {
    this.authService.logout();
    this.router.navigate(['']);
  }
}